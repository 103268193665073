import { DateTime } from 'luxon'

import { StarIcon } from '@heroicons/react/24/solid'
import { ProfilePhotoIcon } from 'assets/icons'
import clsx from 'clsx'
import { getDuration } from 'utils/date'

interface ReviewProps {
	author: string
	rating: number
	review: string
	insertedAt: string
	profile: string
}

export const Review = ({ author, review, insertedAt, profile, rating }: ReviewProps) => {
	return (
		<div className="flex gap-x-4 overflow-x-hidden">
			<img
				src={profile === 'crm' ? ProfilePhotoIcon : profile}
				alt="user"
				className="lg:w-14 lg:h-14 shrink-0 w-[30px] h-[30px] object-cover rounded-full"
			/>

			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
				className="px-6 pt-4 pb-3 border border-[#D3E3F1] rounded-xl w-full flex flex-col gap-y-3.5">
				<div className="flex flex-col gap-y-3">
					<div className="flex justify-between items-start">
						<div className="flex items-center justify-between">
							<div className="flex flex-col gap-y-0.5">
								<h5 className="text-primary max-md:text-xs whitespace-nowrap font-bold">
									{author}
								</h5>
								<div className="flex items-center">
									{[1, 2, 3, 4, 5].map(star => (
										<StarIcon
											key={star}
											className={clsx(
												rating >= star
													? 'text-[#FFB400] stroke-[#FFB400]'
													: 'text-white stroke-[#FFB400]',
												'md:h-4 md:w-4 h-3 w-3 flex-shrink-0'
											)}
											aria-hidden="true"
										/>
									))}
								</div>
							</div>
						</div>
						<p className="text-[#12325880] text-xs">{getDuration(DateTime.fromISO(insertedAt))}</p>
					</div>
					<p className="text-primary">{review}</p>
				</div>
			</div>
		</div>
	)
}
