import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormReset,
	UseFormResetField,
	UseFormSetValue,
	UseFormWatch,
	useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import {
	CashIcon,
	CheckmarkIcon,
	DangerIcon,
	EllipseBottomIcon,
	EllipseRightIcon,
	ExclamationMarkIcon,
	FormBannerIcon,
	InputCalendarIcon,
	OnlineIcon,
	OutlineGlobeIcon,
	OutlineLocationIcon,
	OutlineMailIcon,
	OutlinePhoneIcon,
	ProfilePhotoIcon
} from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Breadcrumb } from 'components/app/breadcrumb'
import { Button } from 'components/app/button'
import { ImageUpload } from 'components/app/image-upload'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { Tabs } from 'components/app/tabs'
import { TimePicker } from 'components/app/time-picker'
import { Login } from 'components/auth/login'
import { Otp } from 'components/auth/otp'
import { Register } from 'components/auth/register'
import { InputNumber, LabelInput } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { InputRadioGroup } from 'components/inputs/radio-group'
import { Select, SelectLabel } from 'components/inputs/select'
import { ProgressBar } from 'components/progress-bar/progress-bar'
import { AppointmentStatus, AuthOptions, Language } from 'constants/constants'
import { blankIdentityForm } from 'constants/form-defaults'
import { useAppDispatch, useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { Link } from 'react-router-dom'
import appointmentService from 'services/appointment-service'
import authService from 'services/auth-service'
import categoryService from 'services/category-service'
import companyService from 'services/company-service'
import relationService from 'services/relation-service'
import service from 'services/service'
import userService from 'services/user-service'
import { saveVisitForm } from 'slices/appointment'
import { login } from 'slices/auth'
import { socket } from 'sockets/socket-context'
import { appendDateTime, getTimeRange } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'
import { calculateServicePrice } from 'utils/price'

enum FormSteps {
	VISITREASON,
	USERVERIFICATION,
	IDENTITYCONFIRM,
	QUESTIONS,
	CONFIRMAPPOINTMENT
}

export const CreateAppointment = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const [searchParams] = useSearchParams()
	const companyId = searchParams.get('id')
	const tKey = getTKey('appointment')

	const appLanguage = getAppLang()

	const [filteredServices, setFilteredServices] = useState<Service[]>([])

	const schema = yup.object({
		category: yup.string().when('$step', {
			is: FormSteps.VISITREASON,
			then: schema => schema.required(t(tKey('create.errors.category')))
		}),
		services: yup.string().when('$step', {
			is: FormSteps.VISITREASON,
			then: schema => schema.required(t(tKey('create.errors.services')))
		}),
		id_employee: yup
			.string()
			.test('employee-validation', t(tKey('create.errors.employee')), function (value) {
				const selectedService = this.parent.services
				if (
					(filteredServices?.find(service => service.name === selectedService)?.id_employees
						.length as number) > 0
				) {
					if (!value) return false
					return true
				}
				return true
			}),
		appointmentDate: yup.date().when('$step', {
			is: FormSteps.VISITREASON,
			then: schema =>
				schema
					.required(t(tKey('create.errors.appointmentDate')))
					.typeError(t(tKey('create.errors.appointmentDate')))
		}),
		timeSlot: yup.string().when('$step', {
			is: FormSteps.VISITREASON,
			then: schema => schema.required(t(tKey('create.errors.timeSlot')))
		}),
		username: yup.string().test('username-test', t('auth.errors.username'), function (value) {
			if (selectedTab === 'login' && !otpVisible && !value) return false
			return true
		}),
		fname: yup.string().test('fname-test', t('auth.errors.firstname'), function (value) {
			if (selectedTab === 'signup' && !otpVisible && !value) return false
			if (steps === FormSteps.IDENTITYCONFIRM && !value) return false
			return true
		}),
		lname: yup.string().test('lname-test', t('auth.errors.lastname'), function (value) {
			if (selectedTab === 'signup' && !otpVisible && !value) return false
			if (steps === FormSteps.IDENTITYCONFIRM && !value) return false
			return true
		}),
		password: yup.string().test('password-test', t('auth.errors.loginPassword'), function (value) {
			if ((selectedTab === 'signup' || selectedTab === 'login') && !otpVisible && !value)
				return false
			if (
				(selectedTab === 'signup' || selectedTab === 'login') &&
				!otpVisible &&
				value &&
				value?.length < 8
			) {
				return this.createError({
					path: 'password',
					message: t('auth.errors.minPassword')
				})
			}
			return true
		}),
		confirmPassword: yup
			.string()
			.test('confirm-password-test', t('auth.errors.confirmPassword'), function (value) {
				if (selectedTab === 'signup' && !otpVisible && !value) return false
				if (selectedTab === 'signup' && !otpVisible && value !== this.parent.password) {
					return this.createError({
						path: 'confirmPassword',
						message: t('auth.errors.matchPassword')
					})
				}
				return true
			}),
		phonenumber: yup
			.string()
			.test('phonenumber-test', t('auth.errors.phonenumber'), function (value) {
				if (selectedTab === 'signup' && !otpVisible && !value) return false
				if (selectedTab === 'signup' && !otpVisible && value && value?.length > 13) {
					return this.createError({
						path: 'phonenumber',
						message: t('auth.errors.maxPhone')
					})
				}
				return true
			}),
		email: yup.string().test('email-test', t('auth.errors.email'), function (value) {
			if (selectedTab === 'signup' && !otpVisible && !value) return false
			return true
		}),
		agreement: yup.string().test('agreement-test', t('auth.errors.termsAgree'), function (value) {
			if (selectedTab === 'login' && !otpVisible && !value) return false
			return true
		}),
		gender: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.gender'))
		}),
		relatedPerson: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.relation'))
		}),
		insuranceNumber: yup
			.string()
			.test('insurance-number-test', t('auth.errors.validInsuranceNumber'), function (value) {
				if (steps === FormSteps.IDENTITYCONFIRM && value && !/^80756\d{15}$/.test(value)) {
					return false
				}
				return true
			}),
		birthDate: yup
			.string()
			.nullable()
			.test('birth-date-test', t('auth.errors.dob'), function (value) {
				if (selectedTab === 'signup' && !otpVisible && !value) return false
				if (steps === FormSteps.IDENTITYCONFIRM && !value) return false
				if (
					((selectedTab === 'signup' && !otpVisible) ||
						(steps === FormSteps.IDENTITYCONFIRM && this.parent.relatedPerson === 'Myself')) &&
					value &&
					new Date(value) > new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000)
				) {
					return this.createError({
						path: 'birthDate',
						message: t('auth.errors.dobAge')
					})
				}
				return true
			}),
		pID: yup.string().notRequired(),
		address: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.street'))
		}),
		streetNumber: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.streetNumber'))
		}),
		country: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.country'))
		}),
		city: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.city'))
		}),
		zip: yup.string().when('$step', {
			is: FormSteps.IDENTITYCONFIRM,
			then: schema => schema.required(t('auth.errors.zip'))
		}),
		acceptAgb: yup.boolean().when('$step', {
			is: FormSteps.CONFIRMAPPOINTMENT,
			then: schema => schema.oneOf([true], t(tKey('create.errors.acceptAgb')))
		})
	})

	const visitReasonForm = useAppSelector(state => state.appointment.visitReasonForm)
	const auth = useAppSelector(state => state.auth)
	const appointmentData = useAppSelector(state => state.appointment.visitReasonForm)

	const [selectedTab, setSelectedTab] = useState('selection')
	const [additionalInfo, setAdditionalInfo] = useState('')
	const [isAppointmentAllowed, setIsAppointmentAllowed] = useState(true)
	const [showWarningModal, setShowWarningModal] = useState(false)
	const [selectedPaymentOption, setSelectedPaymentOption] = useState('onsite')
	const [otpVisible, setOtpVisible] = useState(false)
	const [showModal, setShowModal] = useState({ confirmation: false, success: false })
	const [showPaymentForm, setShowPaymentForm] = useState(false)
	const [user, setUser] = useState<User>(useAppSelector(state => state.user))
	const [company, setCompany] = useState<Company>()
	const [appointmentId, setAppointmentId] = useState<string>()
	const [steps, setSteps] = useState(FormSteps.VISITREASON)
	const [companyLogo, setCompanyLogo] = useState<string>()
	const [services, setServices] = useState<Service[]>([])
	const [categories, setCategories] = useState<CompanyCategory[]>([])
	const [pin, setPin] = useState('')
	const [formData, setFormData] = useState({} as Auth)
	const [image, setImage] = useState<File>()
	const [isBooking, setBooking] = useState(false)
	const [isLoggedIn, _setIsLoggedIn] = useState(auth.accessToken ? true : false)
	const [relatedPersonId, setRelatedPersonId] = useState<string>()
	const [newRelatedPersonId, setNewRelatedPersonId] = useState<string>()
	const [relations, setRelations] = useState<Relation[]>([])
	const [questions, setQuestions] = useState(
		{} as { [question: string]: { value: string; group: string } }
	)
	const [isLoading, setIsLoading] = useState({
		login: false,
		register: false,
		otp: false
	})

	useEffect(() => {
		if (auth.accessToken) {
			setTimeout(() => {
				userService.getUserInfo().then(res => setUser(res))
				relationService.getAllRelations().then(res => setRelations(res))
			}, 500)
		}
	}, [auth])

	useEffect(() => {
		if (relatedPersonId) {
			const selectedRelatedPerson = relations.find(
				r => relatedPersonId?.includes(r.fname) && relatedPersonId?.includes(r.lname)
			)
			if (selectedRelatedPerson) {
				const birthDateTime = DateTime.fromMillis(selectedRelatedPerson?.birthDate as number)
				const now = DateTime.now()
				const diff = now.diff(birthDateTime, 'years').years
				setIsAppointmentAllowed(Math.floor(diff) < 18)
			} else {
				setIsAppointmentAllowed(true)
			}
		}
	}, [relatedPersonId])

	useEffect(() => {
		if (company) {
			companyService.getCompanyLogo(company._id).then(res => setCompanyLogo(res[0].url))
		}
	}, [company])

	useEffect(() => {
		if (companyId) {
			companyService.getCompanyById(companyId).then(res => setCompany(res))
			service
				.getCompanyServices(companyId)
				.then(res => setServices(res.filter(r => r.is_active === 'aktiv')))
			categoryService
				.getCompanyCategories(companyId)
				.then(res => setCategories(res.filter(r => r.is_active === 'aktiv')))
		}
	}, [companyId])

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		resetField,
		formState: { errors },
		control
	} = useForm<AppointmentForm>({
		resolver: yupResolver(schema as any),
		defaultValues: isMobile
			? {
					...(visitReasonForm as any),
					country: appLanguage === Language.EN ? 'Switzerland' : 'Schweiz',
					acceptAgb: false
				}
			: { country: appLanguage === Language.EN ? 'Switzerland' : 'Schweiz', acceptAgb: false },
		context: { step: steps },
		mode: 'all'
	})

	const relatedPerson = watch('relatedPerson')

	useEffect(() => {
		if (relatedPerson) {
			if (relatedPerson === 'Myself') {
				reset({
					...user,
					birthDate: DateTime.fromMillis(Number(user.birthDate)).toISODate() as any,
					relatedPerson: 'Myself'
				} as any)
			} else if (relatedPerson === 'other') {
				reset(blankIdentityForm as any)
			} else {
				relationService.getAllRelations().then(res => {
					const relationData = res.find(
						r => relatedPerson?.includes(r.fname) && relatedPerson?.includes(r.lname)
					) as Relation
					const name = relationData?.fname + ' ' + relationData?.lname
					reset({
						...relationData,
						relatedPerson: name,
						birthDate: DateTime.fromJSDate(new Date(relationData.birthDate)).toISODate() as any
					} as any)
				})
			}
		}
	}, [relatedPerson])

	useEffect(() => {
		isLoggedIn ? setSteps(FormSteps.IDENTITYCONFIRM) : setSteps(FormSteps.USERVERIFICATION)
		reset()
	}, [isMobile])

	const sendOtp = () => {
		authService
			.sendMobileVerification(
				formData.phonenumber || formData.username,
				selectedTab === 'signup',
				formData.password
			)
			.then(() => {
				toast.success(t('auth.toast.verificationSent'))
			})
			.catch(err => {
				if (err.response && err.response.data) {
					return toast.error(err.response.data.message)
				}
				toast.error(t('auth.toast.error'))
			})
	}

	const nextStep = handleSubmit(data => {
		if (steps === FormSteps.CONFIRMAPPOINTMENT) {
			setShowPaymentForm(true)
		}
		if (steps === FormSteps.VISITREASON) {
			dispatch(
				saveVisitForm({
					visitReasonForm: {
						appointmentDate: data.appointmentDate,
						category: data.category,
						services: data.services,
						id_employee: data.id_employee,
						timeSlot: data.timeSlot
					}
				})
			)
			if (isLoggedIn) return setSteps(prev => prev + 2)
			return setSteps(prev => prev + 1)
		}
		if (steps === FormSteps.QUESTIONS) {
			return setSteps(prev => prev + 1)
		}

		if (steps === FormSteps.USERVERIFICATION) {
			if (otpVisible) {
				if (!pin || pin.length !== 6) {
					return toast.error(t('auth.toast.validPin'))
				}
				setIsLoading(prev => ({ ...prev, otp: true }))
				authService
					.mobileVerification(formData.phonenumber || formData.username, pin)
					.then(() => {
						toast.success(t('auth.toast.verificationSuccess'))
						if (selectedTab === 'login') {
							dispatch(login({ username: formData.username, password: formData.password }))
								.then(() =>
									setTimeout(() => {
										userService.getUserInfo().then(res => setUser(res))
										setSteps(prev => prev + 1)
									}, 3000)
								)
								.catch(() => toast.error(t('auth.toast.invalidCredentials')))
								.finally(() => setIsLoading(prev => ({ ...prev, login: false })))
						} else if (selectedTab === 'signup') {
							authService
								.register({
									...formData,
									birthDate: DateTime.fromFormat(
										formData.birthDate as any,
										'yyyy-LL-dd'
									).toMillis() as any,
									phone: formData.phonenumber
								})
								.then(() => {
									toast.success(t('auth.toast.registrationSuccess'))
									authService.sendRegistrationEmail({
										name: formData.fname,
										phone: formData.phonenumber,
										email: formData.email,
										birthDate: DateTime.fromFormat(formData.birthDate as any, 'yyyy-LL-dd')
											.setLocale(appLanguage)
											.toFormat('yyyy-LL-dd') as any,
										password: formData.password
									})
									dispatch(login({ username: formData.phonenumber, password: formData.password }))
										.then(() =>
											setTimeout(() => {
												userService.getUserInfo().then(res => setUser(res))
												setSteps(prev => prev + 1)
											}, 3000)
										)
										.catch(() => toast.error(t('auth.toast.invalidCredentials')))
										.finally(() => setIsLoading(prev => ({ ...prev, login: false })))
								})
								.catch(err => {
									if (err.response && err.response.data) {
										return toast.error(err.response.data.message)
									}
									toast.error(t('auth.toast.registrationError'))
								})
						}
					})
					.catch(err => {
						if (err.response && err.response.data) {
							return toast.error(err.response.data.message)
						}
						toast.error(t('auth.toast.validationError'))
					})
					.finally(() => setIsLoading(prev => ({ ...prev, otp: false })))
			} else if (selectedTab === 'login') {
				setIsLoading(prev => ({ ...prev, login: true }))
				setFormData(data)
				authService
					.sendMobileVerification(data.username, false, data.password)
					.then(() => {
						toast.success(t('auth.toast.verificationSent'))
						setOtpVisible(true)
					})
					.catch(err => {
						if (err.response && err.response.data) {
							return toast.error(err.response.data.message)
						}
						toast.error(t('auth.toast.error'))
					})
					.finally(() => setIsLoading(prev => ({ ...prev, login: false })))
			} else if (selectedTab === 'signup') {
				setIsLoading(prev => ({ ...prev, register: true }))
				setFormData(data)
				authService
					.sendMobileVerification(data.phonenumber, true)
					.then(() => {
						toast.success(t('auth.toast.verificationSent'))
						setOtpVisible(true)
					})
					.catch(err => {
						if (err.response && err.response.data) {
							return toast.error(err.response.data.message)
						}
						toast.error(t('auth.toast.error'))
					})
					.finally(() => setIsLoading(prev => ({ ...prev, register: false })))
			}
		}
		if (steps === FormSteps.IDENTITYCONFIRM) {
			if (!isAppointmentAllowed) {
				return setShowWarningModal(true)
			}
			const formData = new FormData()
			if (relatedPerson === 'Myself') {
				formData.append('address', data.address)
				formData.append('birthDate', DateTime.fromISO(data.birthDate as any).toMillis() as any)
				formData.append('city', data.city)
				formData.append('country', data.country)
				formData.append('fname', data.fname)
				formData.append('lname', data.lname)
				formData.append('gender', data.gender)
				formData.append('insuranceNumber', data.insuranceNumber)
				formData.append('pID', data.pID)
				formData.append('streetNumber', data.streetNumber)
				formData.append('zip', data.zip)

				userService.updateUserInfo(formData).then(() => setSteps(prev => prev + 1))
			} else if (relatedPerson === 'other') {
				const payload = {
					address: data.address,
					birthDate: DateTime.fromISO(data.birthDate as any).toMillis(),
					city: data.city,
					country: data.country,
					fname: data.fname,
					lname: data.lname,
					gender: data.gender,
					insuranceNumber: data.insuranceNumber,
					relation: data.relation,
					streetNumber: data.streetNumber,
					zip: data.zip
				}
				relationService.createRelation(payload as any).then(res => {
					setNewRelatedPersonId(res.id)
					setSteps(prev => prev + 1)
				})
			} else {
				setSteps(prev => prev + 1)
			}
		}
	})

	const onSubmit = handleSubmit(data => {
		createAppointment(data)
	})

	const createAppointment = (data: AppointmentForm) => {
		setBooking(true)
		const payload = {
			booking_as: data?.relatedPerson === 'myself' ? data?.relatedPerson : 'others',
			relatedPerson:
				data?.relatedPerson === 'myself'
					? undefined
					: newRelatedPersonId
						? newRelatedPersonId
						: relations.find(
								r => relatedPersonId?.includes(r.fname) && relatedPersonId?.includes(r.lname)
							)?._id,
			company_name: company?.company_name,
			payment_mode: selectedPaymentOption,
			additonal_information: additionalInfo,
			from: appendDateTime(appointmentData.appointmentDate, appointmentData.timeSlot),
			to: appendDateTime(
				appointmentData.appointmentDate,
				DateTime.fromFormat(appointmentData.timeSlot, 'HH:mm')
					.plus({
						minutes: Number(
							services.find(ser => ser.name === appointmentData.services)?.duration ?? 0
						)
					})
					.toFormat('HH:mm')
			),
			id_category: categories.find(c => c.category_name === appointmentData.category)?._id,
			id_client: user?._id,
			id_company: company?._id,
			status: selectedPaymentOption === 'online' ? 'processing' : AppointmentStatus.PENDING,
			questions: questions,
			id_service: services.find(s => s.name === appointmentData.services)?._id,
			id_employee: appointmentData.id_employee ? appointmentData.id_employee : undefined,
			service: services.find(s => s.name === appointmentData.services),
			service_name: appointmentData.services
		}

		appointmentService
			.createAppointment(payload as any)
			.then((res: any) => {
				if (selectedPaymentOption === 'online') {
					window.location.href = res.url
				}
				socket.emit('Appointment created', {
					appointment: res.data
				})
				if (selectedPaymentOption === 'onsite') {
					setAppointmentId(res._id)
					setShowModal(prev => ({ ...prev, confirmation: true }))
				}
			})
			.catch(err =>
				toast.error(err?.response?.data?.message ?? t(tKey('create.toast.appointmentError')))
			)
			.finally(() => setBooking(false))
	}

	const renderComponent = cond([
		[
			steps === FormSteps.VISITREASON,
			() => (
				<VisitReasonForm
					{...{ errors, register, control, watch, setValue }}
					company={company as Company}
					filteredServices={filteredServices}
					setFilteredServices={setFilteredServices}
					logo={companyLogo as string}
					services={services}
					categories={categories}
				/>
			)
		],
		[
			steps === FormSteps.USERVERIFICATION,
			() => (
				<UserVerificationForm
					phonenumber={
						selectedTab === 'login'
							? !formData.username?.includes('@')
								? formData.username
								: ''
							: formData?.phonenumber
					}
					isLoading={isLoading}
					{...{
						errors,
						register,
						watch,
						setValue,
						control,
						resetField,
						reset,
						selectedTab,
						setSelectedTab,
						otpVisible,
						setOtpVisible,
						pin,
						setPin,
						sendOtp
					}}
				/>
			)
		],
		[
			steps === FormSteps.IDENTITYCONFIRM,
			() => (
				<IdentityForm
					setImage={setImage}
					relations={relations}
					{...{ errors, register, control, watch, setRelatedPersonId }}
				/>
			)
		],
		[
			steps === FormSteps.QUESTIONS,
			() => (
				<QuestionsForm
					additionalInfo={additionalInfo}
					setAdditionalInfo={setAdditionalInfo}
					answeredQuestions={questions}
					setAnsweredQuestions={setQuestions}
					services={services}
					{...{ errors, register, control }}
				/>
			)
		],
		[
			steps === FormSteps.CONFIRMAPPOINTMENT && !showPaymentForm,
			() => (
				<ConfirmAppointmentForm
					company={company as Company}
					relation={
						relations.find(
							r => relatedPersonId?.includes(r.fname) && relatedPersonId?.includes(r.lname)
						) as Relation
					}
					additionalInfo={additionalInfo}
					register={register}
					errors={errors}
					logo={companyLogo as string}
					services={services}
				/>
			)
		],
		[
			steps === FormSteps.CONFIRMAPPOINTMENT && showPaymentForm,
			() => (
				<PaymentOptionForm
					selectedPayment={selectedPaymentOption}
					setSelectedPayment={setSelectedPaymentOption}
				/>
			)
		]
	])

	return (
		<AppLayout
			onLogoClick={() => navigate(`/company-details/${companyId}`)}
			renderDashboardHeader={!!auth.accessToken}
			renderHeader={!auth.accessToken}>
			{showWarningModal && (
				<Modal width={isMobile ? 'w-[344px]' : 'w-[512px]'} showCrossIcon={false}>
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col max-lg:items-center lg:flex-row gap-x-4">
							<img src={DangerIcon} className="shrink-0 w-[40px] h-[40px]" />
							<div className="flex max-lg:items-center flex-col gap-y-2">
								<h2 className="font-domine text-lg text-primary">
									{t(tKey('create.headings.appointmentNotAllowed'))}
								</h2>
								<p className="text-[#7F9AB2] text-sm">{t(tKey('create.labels.createAccount'))}</p>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row lg:justify-end lg:items-center max-lg:gap-y-5 gap-x-3">
							<button
								onClick={() => setShowWarningModal(false)}
								className="button-shadow border border-[#D1D5DB] rounded-md px-[17px] py-[9px] text-sm text-[#7F9AB2] hover:text-black hover:border-black">
								{t(tKey('create.buttons.cancel'))}
							</button>
							<button
								onClick={() => navigate(`/auth?tab=${AuthOptions.REGISTER}`)}
								className="button-shadow rounded-md px-[17px] py-[9px] bg-[#DC2626] hover:bg-red-700 text-white">
								{t(tKey('create.buttons.createAccount'))}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showModal.success && (
				<Modal
					showCrossIcon={true}
					isFullHeight
					onClose={() => {
						setShowModal({ confirmation: false, success: false })
						navigate(`/company-details/${companyId}`)
					}}>
					<AppLayout
						onLogoClick={() => navigate(`/company-details/${companyId}`)}
						renderDashboardHeader={isMobile}>
						<div className="flex flex-col py-[100px] px-[24px]">
							<img src={CheckmarkIcon} alt="Checkmark" className=" mx-auto mb-10 object-contain" />

							<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
								{t(tKey('create.headings.created'))}
							</h1>
							<p className="text-secondary leading-6 text-base font-nunito text-center font-normal">
								{t(tKey('create.headings.details'))}
							</p>
						</div>
					</AppLayout>
				</Modal>
			)}

			<div className="relative flex justify-center items-center lg:-mt-4">
				<img src={FormBannerIcon} className="w-full max-lg:h-[70px]" />
				<h1 className="absolute font-domine lg:font-bold text-lg lg:text-[22px] text-black">
					{t('companyDetails.makeAppointment')}
				</h1>
			</div>
			<div className="lg:px-14 px-6 pt-8">
				<Breadcrumb
					options={[
						{
							name: t('appointment.create.headings.company'),
							href: `/company-details/${companyId}`,
							current: false
						},
						{
							name: t('appointment.create.headings.createAppointment'),
							href: `/create-appointment?id=${companyId}`,
							current: true
						}
					]}
					showHome={false}
					theme="dark"
				/>
			</div>
			<div className="lg:pt-14 pt-10 flex flex-col pb-24 max-lg:pb-10 items-center">
				<ProgressBar
					totalSteps={isLoggedIn ? 4 : 5}
					currentStep={isLoggedIn ? steps - 1 : steps}
					stepNames={
						isLoggedIn
							? [
									t(tKey('progressBar.visitReason')),
									t(tKey('progressBar.identityConfirm')),
									t(tKey('progressBar.questions')),
									t(tKey('progressBar.confirmAppointment'))
								]
							: [
									t(tKey('progressBar.visitReason')),
									t(tKey('progressBar.userVerification')),
									t(tKey('progressBar.identityConfirm')),
									t(tKey('progressBar.questions')),
									t(tKey('progressBar.confirmAppointment'))
								]
					}
				/>
			</div>
			<div
				className={clsx('lg:px-14 lg:pb-[81px] flex gap-x-11', {
					'lg:justify-center': steps === FormSteps.CONFIRMAPPOINTMENT
				})}>
				<form
					onSubmit={event => {
						if (
							steps === FormSteps.CONFIRMAPPOINTMENT &&
							(company?.payment_status === false || showPaymentForm)
						) {
							onSubmit(event)
						} else {
							nextStep(event)
						}
					}}
					className={clsx(
						'lg:form-shadow py-8 lg:px-6 px-2 rounded-xl lg:basis-[55%] flex flex-col gap-y-8 basis-full',
						{ '!lg:basis-[65%]': steps === FormSteps.CONFIRMAPPOINTMENT && !showPaymentForm },
						selectedTab === 'selection' && steps === FormSteps.USERVERIFICATION
							? 'lg:shadow-none lg:px-0 lg:py-0'
							: ''
					)}>
					<>{renderComponent}</>
					{showModal.confirmation && (
						<Modal
							showCrossIcon={false}
							width="lg:w-[690px] w-full"
							onClose={() => setShowModal(prev => ({ ...prev, confirmation: false }))}>
							<AppLayout
								onLogoClick={() => navigate(`/company-details/${companyId}`)}
								renderDashboardHeader={isMobile}>
								<div className="flex flex-col lg:gap-y-6 gap-y-5 max-lg:mt-6">
									<div className="lg:pb-5 lg:border-b lg:border-[#D3E3F1] flex gap-x-3">
										<img
											src={companyLogo}
											className="lg:w-[76px] lg:h-[76px] w-[65px] h-[61px] object-cover rounded-lg"
										/>

										<div className="flex flex-col gap-y-2 lg:grow">
											<h4 className="font-domine text-primary lg:text-[20px] text-lg font-bold lg:line-clamp-1 lg:whitespace-nowrap lg:overflow-hidden lg:text-ellipsis">
												{company?.company_name}
											</h4>
											<div className="flex justify-between items-center">
												<div className="flex">
													{company?.company_type?.slice(0, 2).map((item, index) => (
														<div key={item} className="flex items-center">
															{index > 0 && (
																<div className="w-[10px] h-[10px] bg-[#D9D9D9] rounded-full mx-3" />
															)}
															<span className="text-secondary max-lg:text-sm font-semibold">
																{item}
															</span>
														</div>
													))}
												</div>
												<div className="flex gap-x-2 items-center whitespace-nowrap max-lg:hidden">
													<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
													<p className="text-sm text-secondary">
														{DateTime.fromISO(appointmentData.appointmentDate as any)
															.setLocale(appLanguage)
															.toFormat('cccc, dd. MMM yyyy')}
													</p>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none">
														<path
															d="M9.99935 18.3263C14.6017 18.3263 18.3327 14.5954 18.3327 9.993C18.3327 5.39063 14.6017 1.65967 9.99935 1.65967C5.39698 1.65967 1.66602 5.39063 1.66602 9.993C1.66602 14.5954 5.39698 18.3263 9.99935 18.3263Z"
															stroke="#123258"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M10 4.99316V9.99316L13.3333 11.6598"
															stroke="#123258"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
													<p className="text-sm text-secondary">
														{appointmentData.timeSlot} -{' '}
														{DateTime.fromFormat(appointmentData.timeSlot, 'HH:mm')
															.plus({
																minutes: Number(
																	services.find(ser => ser.name === appointmentData.services)
																		?.duration ?? 0
																)
															})
															.toFormat('HH:mm')}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className=" border-b border-[#D3E3F1] text-sm pb-3.5 -mt-1 lg:hidden flex jus gap-x-2 items-center whitespace-nowrap">
										<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
										<p className="text-sm text-secondary">
											{DateTime.fromISO(appointmentData.appointmentDate as any)
												.setLocale(appLanguage)
												.toFormat('cccc, dd. MMM yyyy')}
										</p>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											className="shrink-0"
											fill="none">
											<path
												d="M9.99935 18.3263C14.6017 18.3263 18.3327 14.5954 18.3327 9.993C18.3327 5.39063 14.6017 1.65967 9.99935 1.65967C5.39698 1.65967 1.66602 5.39063 1.66602 9.993C1.66602 14.5954 5.39698 18.3263 9.99935 18.3263Z"
												stroke="#123258"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M10 4.99316V9.99316L13.3333 11.6598"
												stroke="#123258"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<p className="text-sm text-secondary">
											{appointmentData.timeSlot} -{' '}
											{DateTime.fromFormat(appointmentData.timeSlot, 'HH:mm')
												.plus({
													minutes: Number(
														services.find(ser => ser.name === appointmentData.services)?.duration ??
															0
													)
												})
												.toFormat('HH:mm')}
										</p>
									</div>
									<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
										<h4 className="font-domine lg:text-lg font-bold text-primary">
											{t('companyDetails.services')}
										</h4>
										<div className="flex gap-x-3.5">
											<div className="px-3 py-0.5 rounded-full bg-[#D3E3F180] text-secondary">
												{appointmentData.services}
											</div>
										</div>
									</div>

									{appointmentData.id_employee && (
										<div className="flex flex-col gap-y-3.5">
											<h4 className="font-domine text-lg font-bold text-primary">
												{t('companyDetails.employee')}
											</h4>
											<p className="text-secondary">
												{services
													.find(ser => ser.name === appointmentData.services)
													?.id_employees.find(emp => emp._id === appointmentData.id_employee)
													?.fname +
													' ' +
													services
														.find(ser => ser.name === appointmentData.services)
														?.id_employees.find(emp => emp._id === appointmentData.id_employee)
														?.lname}
											</p>
										</div>
									)}
									<div className="flex flex-col gap-y-3.5">
										<h4 className="font-domine text-lg font-bold text-primary">
											{t('companyDetails.description')}
										</h4>
										<div className="flex gap-x-3.5">
											<div className="text-secondary">
												{services.find(s => s.name === appointmentData.services)?.description}
											</div>
										</div>
									</div>

									<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
										<h4 className="font-domine lg:text-lg font-bold text-primary">
											{t('appointment.additionalInformation')}
										</h4>

										<p className="text-secondary">
											{additionalInfo ? additionalInfo : t('appointment.noAdditionalInformation')}
										</p>
									</div>

									<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
										<h4 className="font-domine lg:text-lg font-bold text-primary">
											{t('appointment.paymentMethod')}
										</h4>
										<p className="text-secondary">{t('appointment.onsite')}</p>
									</div>
									<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
										<h4 className="font-domine lg:text-lg font-bold text-primary">
											{t('userDashboard.labels.bookingFor')}
										</h4>
										<p className="text-secondary">
											{relations.find(
												r =>
													relatedPersonId?.includes(r.fname) && relatedPersonId?.includes(r.lname)
											)?.relation ?? 'Myself'}
										</p>
									</div>

									<div className="p-2.5 shadow-xl mx-auto shadow-[#D3E3F1]">
										<QRCode
											className="w-[164px] h-[164px]"
											value={`https://crm.appointo.ch/appointment-details/${appointmentId}`}
										/>
									</div>
									<div className="flex gap-x-2.5 p-2 bg-[#FFFBEB] rounded items-center">
										<img src={ExclamationMarkIcon} />
										<p className="text-[#92400E] lg:text-sm text-xs">
											{t('companyDetails.thanksNote')}
										</p>
									</div>
									<div className="flex flex-col lg:gap-y-5 gap-y-3.5">
										<h4 className="font-domine lg:text-lg font-bold text-primary">
											{t('companyDetails.contactDetails')}
										</h4>
										<div className="flex flex-col gap-y-4">
											<div className="flex gap-x-2 max-lg:items-center">
												<img src={OutlinePhoneIcon} className="max-lg:h-6 max-lg:w-6" />
												<p className="text-primary max-lg:text-sm">{company?.phone}</p>
											</div>
											<div className="flex gap-x-2 max-lg:items-center">
												<img src={OutlineMailIcon} className="max-lg:h-6 max-lg:w-6" />
												<p className="text-primary max-lg:text-sm">{company?.email}</p>
											</div>
											<div className="flex gap-x-2 max-lg:items-center">
												<img src={OutlineGlobeIcon} className="max-lg:h-6 max-lg:w-6" />
												<p className="text-primary max-lg:text-sm">{company?.website}</p>
											</div>
											<div className="flex gap-x-2 max-lg:items-center">
												<img src={OutlineLocationIcon} className="max-lg:h-6 max-lg:w-6" />
												<p className="text-primary max-lg:text-sm">{`${company?.address} ${company?.address_no}, ${company?.zip} ${company?.city}`}</p>
											</div>
										</div>
									</div>

									<div className="flex flex-col gap-y-[13px]">
										<div className="py-[15px] lg:px-[131px] bg-[#D3E3F14D] text-primary items-center justify-center flex flex-col gap-y-2">
											<span className="text-sm">Total</span>
											<span className="font-bold font-domine text-sm">
												{calculateServicePrice(
													services
														.find(s => s.name === appointmentData.services)
														?.price.toFixed(2) as string,
													services.find(s => s.name === appointmentData.services)
														?.health_insurance === '1'
												)}
											</span>
										</div>
										<Button
											onClick={() => setShowModal({ confirmation: false, success: true })}
											className="max-lg:font-bold">
											{t('companyDetails.done')}
										</Button>
									</div>
								</div>
							</AppLayout>
						</Modal>
					)}
					<div
						className={clsx(
							steps !== FormSteps.USERVERIFICATION &&
								'max-lg:grid max-lg:grid-cols-1 gap-y-5 lg:ml-auto',
							steps === FormSteps.CONFIRMAPPOINTMENT
								? 'grid grid-cols-2 gap-x-5'
								: 'gap-x-2.5 lg:ml-auto'
						)}>
						{steps === FormSteps.CONFIRMAPPOINTMENT && (
							<>
								<button
									type="button"
									onClick={() =>
										isMobile ? setSteps(FormSteps.VISITREASON) : setSteps(FormSteps.IDENTITYCONFIRM)
									}
									className="bg-white w-full max-lg:-mt-2 border rounded border-primary py-3  text-primary max-lg:text-sm">
									{isMobile
										? t(tKey('buttons.newCancel'))
										: t(tKey('buttons.newAppointmentCancel'))}
								</button>
								<Button
									type="submit"
									disabled={isBooking}
									className="lg:w-fit w-full max-lg:-mt-2 px-11 py-3 border border-primary max-lg:text-sm">
									{isMobile ? (
										t(tKey('buttons.confirm'))
									) : isBooking ? (
										<div className="flex items-center justify-center gap-x-5">
											<Spinner />
											<span className="animate-pulse whitespace-nowrap">
												{t(tKey('create.labels.pleaseWait'))}
											</span>
										</div>
									) : (
										<span>{t(tKey('buttons.confirmAppointment'))}</span>
									)}
								</Button>
							</>
						)}
						{steps === FormSteps.USERVERIFICATION && selectedTab !== 'selection' && (
							<Button
								type="submit"
								className="lg:w-fit max-lg:mx-auto w-full max-lg:-mt-2 px-11 py-3 border border-primary max-lg:text-sm">
								<span>
									{selectedTab === 'login' ? (
										isLoading.login || isLoading.otp ? (
											<div className="flex items-center justify-center gap-x-5">
												<Spinner />
												<span className="animate-pulse whitespace-nowrap">
													{t('auth.login.labels.pleaseWait')}
												</span>
											</div>
										) : (
											t(tKey('buttons.signIn'))
										)
									) : null}
								</span>

								<span>
									{selectedTab === 'signup' ? (
										isLoading.register || isLoading.otp ? (
											<div className="flex items-center justify-center gap-x-5">
												<Spinner />
												<span className="animate-pulse whitespace-nowrap">
													{t('auth.login.labels.pleaseWait')}
												</span>
											</div>
										) : (
											t(tKey('buttons.signup'))
										)
									) : null}
								</span>
							</Button>
						)}
						{steps !== FormSteps.USERVERIFICATION && steps !== FormSteps.CONFIRMAPPOINTMENT && (
							<>
								<Button className="lg:w-fit w-full px-11 py-3 border border-primary max-lg:text-sm">
									{t(tKey('buttons.next'))}
								</Button>
							</>
						)}
					</div>
				</form>
				{(steps !== FormSteps.CONFIRMAPPOINTMENT ||
					(steps === FormSteps.CONFIRMAPPOINTMENT && showPaymentForm)) && (
					<CompanyOverview
						services={services}
						company={company as Company}
						logo={companyLogo as string}
					/>
				)}
			</div>
		</AppLayout>
	)
}

interface CompanyOverviewProps {
	company: Company
	services: Service[]
	logo: string
}

const CompanyOverview = ({ company, logo, services }: CompanyOverviewProps) => {
	const { t } = useTranslation()
	const appLanguage = getAppLang()

	const appointmentData = useAppSelector(state => state.appointment.visitReasonForm)
	const selectedService = services?.find(ser => ser.name === appointmentData.services)
	return (
		<div className="max-lg:hidden py-8 px-6 form-shadow rounded-xl basis-[35%] flex flex-col gap-y-8 h-fit">
			<div className="pb-5 border-b border-[#D3E3F1] flex gap-x-3">
				<img src={logo} className="w-[76px] h-[76px] object-cover rounded-lg" />
				<div className="flex flex-col gap-y-1">
					<h4 className="font-domine text-primary text-[20px] font-bold">
						{company?.company_name}
					</h4>
					<div className="flex flex-col gap-y-2.5">
						<div className="flex">
							{company?.company_type?.slice(0, 2).map((item, index) => (
								<div key={item} className="flex items-center">
									{index > 0 && (
										<div className="w-[10px] h-[10px] bg-[#D9D9D9] rounded-full mx-3" />
									)}
									<span className="text-secondary font-semibold">{item}</span>
								</div>
							))}
						</div>
						<div className="flex gap-x-2 items-center whitespace-nowrap">
							<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
							<p className="text-sm text-secondary">
								{DateTime.fromISO(appointmentData.appointmentDate as any)
									.setLocale(appLanguage)
									.toFormat('cccc, dd. MMM')}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								className="shrink-0"
								fill="none">
								<path
									d="M9.99935 18.3327C14.6017 18.3327 18.3327 14.6017 18.3327 9.99935C18.3327 5.39698 14.6017 1.66602 9.99935 1.66602C5.39698 1.66602 1.66602 5.39698 1.66602 9.99935C1.66602 14.6017 5.39698 18.3327 9.99935 18.3327Z"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 5V10L13.3333 11.6667"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							{appointmentData.timeSlot && (
								<p className="text-sm text-secondary">
									{appointmentData.timeSlot} -{' '}
									{DateTime.fromFormat(appointmentData.timeSlot, 'HH:mm')
										.plus({
											minutes: Number(
												services.find(ser => ser.name === appointmentData.services)?.duration ?? 0
											)
										})
										.toFormat('HH:mm')}
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-y-3.5">
				<h4 className="font-domine text-lg font-bold text-primary">
					{t('companyDetails.services')}
				</h4>
				<div className="flex gap-x-3.5">
					<div className="px-3 py-0.5 rounded-full bg-[#D3E3F180] text-secondary">
						{appointmentData.services}
					</div>
				</div>
			</div>
			{appointmentData.id_employee && (
				<div className="flex flex-col gap-y-3.5">
					<h4 className="font-domine text-lg font-bold text-primary">
						{t('companyDetails.employee')}
					</h4>
					<p className="text-secondary">
						{selectedService?.id_employees.find(emp => emp._id === appointmentData.id_employee)
							?.fname +
							' ' +
							selectedService?.id_employees.find(emp => emp._id === appointmentData.id_employee)
								?.lname}
					</p>
				</div>
			)}
			<div className="flex flex-col gap-y-3.5">
				<h4 className="font-domine text-lg font-bold text-primary">
					{t('companyDetails.description')}
				</h4>
				<div className="flex gap-x-3.5">
					<div className="text-secondary">
						{services.find(s => s.name === appointmentData.services)?.description}
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-y-5">
				<h4 className="font-domine text-lg font-bold text-primary">
					{t('companyDetails.contactDetails')}
				</h4>
				<div className="flex flex-col gap-y-4">
					<div className="flex gap-x-2">
						<img src={OutlinePhoneIcon} />
						<p className="text-primary">{company?.phone}</p>
					</div>
					<div className="flex gap-x-2">
						<img src={OutlineMailIcon} />
						<p className="text-primary">{company?.email}</p>
					</div>
					<div className="flex gap-x-2">
						<img src={OutlineGlobeIcon} />
						<p className="text-primary">{company?.website}</p>
					</div>
					<div className="flex gap-x-2">
						<img src={OutlineLocationIcon} />
						<p className="text-primary">{`${company?.address} ${company?.address_no}, ${company?.zip} ${company?.city}`}</p>
					</div>
				</div>
			</div>
			<div className="py-[15px] bg-[#D3E3F14D] text-primary items-center justify-center flex flex-col gap-y-2">
				<span>Total</span>
				<span className="font-bold font-domine text-center">
					{calculateServicePrice(
						services.find(s => s.name === appointmentData.services)?.price.toFixed(2) as string,
						services.find(s => s.name === appointmentData.services)?.health_insurance === '1'
					)}
				</span>
			</div>
		</div>
	)
}

interface FormProps {
	register?: UseFormRegister<AppointmentForm>
	errors?: FieldErrors<AppointmentForm>
	control?: Control<AppointmentForm, any>
	setValue?: UseFormSetValue<AppointmentForm>
	resetField?: UseFormResetField<AppointmentForm>
	reset?: UseFormReset<AppointmentForm>
	watch?: UseFormWatch<AppointmentForm>
}

export const VisitReasonForm = ({
	categories,
	services,
	register,
	errors,
	watch,
	company,
	filteredServices,
	setFilteredServices,
	logo,
	setValue
}: FormProps & {
	company: Company
	logo: string
	filteredServices: Service[]
	setFilteredServices: (value: Service[]) => void
	categories: CompanyCategory[]
	services: Service[]
}) => {
	const { t } = useTranslation()
	const tKey = getTKey('appointment.create')

	const selectedTimeSlot = watch?.('timeSlot')
	const selectedCategory = watch?.('category')
	const selectedService = watch?.('services')
	const appointmentDate = watch?.('appointmentDate')
	const employeeId = watch?.('id_employee')

	useEffect(() => {
		setValue?.('category', undefined as any)
	}, [])

	useEffect(() => {
		if (selectedCategory) {
			setValue?.('services', undefined as any)
		}
	}, [selectedCategory])

	useEffect(() => {
		if (selectedCategory) {
			const servicesByCategory = services.filter(
				s => s.id_category === categories.find(c => c.category_name === selectedCategory)?._id
			)
			setFilteredServices(servicesByCategory)
		}
	}, [selectedCategory])

	const [serviceSchedule, setServiceSchedule] = useState<Schedule[]>()

	useEffect(() => {
		if (selectedService && services.length > 0) {
			service
				.getServiceSchedule(services.find(ser => ser.name === selectedService)?._id as string)
				.then(res => setServiceSchedule(res))
		}
	}, [selectedService, services])

	const timeSlots = useMemo(() => {
		const mergedTime: Record<number, string[]> = {}
		setValue?.('timeSlot', '')
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') continue

			const timeRange = getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				false,
				services.find(ser => ser.name === selectedService)?.duration as string,
				schedule.day,
				Number(services.find(service => service.name === selectedService)?.min_booking) / 60
			)

			if (timeRange) {
				Object.entries(timeRange).forEach(([key, value]) => {
					const day = parseInt(key)
					if (!mergedTime[day]) {
						mergedTime[day] = []
					}
					mergedTime[day].push(...value)
				})
			}
		}

		return mergedTime
	}, [serviceSchedule])

	const todayTimeSlots = useMemo(() => {
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') return
			return getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				true,
				services.find(ser => ser.name === selectedService)?.duration as string,
				DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday,
				Number(services.find(service => service.name === selectedService)?.min_booking) / 60
			)
		}
	}, [serviceSchedule])

	return (
		<div className="flex flex-col gap-y-3.5">
			<div className="lg:hidden lg:px-5 pb-5 border-b border-[#D3E3F1] flex gap-x-2.5 items-center">
				<img src={logo} className="w-[65px] h-[61px] rounded-lg object-cover" />
				<div className="flex flex-col gap-y-0.5">
					<h2 className="font-domine text-primary font-bold">{company?.company_name}</h2>
					<div className="flex">
						{company?.company_type?.slice(0, 2).map((item, index) => (
							<div key={item} className="flex items-center">
								{index > 0 && <div className="w-[10px] h-[10px] bg-[#D9D9D9] rounded-full mx-3" />}
								<span className="text-secondary text-sm font-semibold">{item}</span>
							</div>
						))}
					</div>
				</div>
			</div>
			<h2 className="text-primary text-[20px] font-bold max-lg:hidden">
				{t('companyDetails.makeAppointment')}
			</h2>
			<div className="pt-3 max-lg:px-0 flex flex-col gap-y-4 rounded-xl">
				<Select
					className="w-full text-primary rounded border-[#D3E3F1] max-lg:font-normal lg:font-normal"
					register={register}
					errors={errors}
					name="category">
					<option value="">{t(tKey('labels.category'))}</option>
					{categories?.map(category => (
						<option key={category._id} value={category.category_name}>
							{category.category_name}
						</option>
					))}
				</Select>
				<Select
					className="w-full text-primary rounded border-[#D3E3F1] max-lg:font-normal lg:font-normal"
					name="services"
					register={register}
					errors={errors}>
					<option value="">{t(tKey('labels.services'))}</option>
					{selectedCategory &&
						filteredServices?.map(service => (
							<option key={service._id} value={service.name}>
								{service.name}
							</option>
						))}
				</Select>
				{selectedService &&
					(filteredServices?.find(service => service.name === selectedService)?.id_employees
						.length as number) > 0 && (
						<Select
							className="w-full text-primary rounded border-[#D3E3F1] max-lg:font-normal lg:font-normal"
							name="id_employee"
							register={register}
							errors={errors}>
							<option value="">{t(tKey('labels.employee'))}</option>
							{selectedService &&
								filteredServices
									.find(service => service.name === selectedService)
									?.id_employees.map(employee => (
										<option key={employee._id} value={employee._id}>
											{employee.fname + ' ' + employee.lname}
										</option>
									))}
						</Select>
					)}
				<div className="relative z-20 flex items-center">
					{selectedService && (
						<TimePicker
							companyId={company._id}
							employeeId={employeeId}
							timeSlots={timeSlots as { [day: number]: string[] }}
							todayTimeSlots={todayTimeSlots as { [day: number]: string[] }}
							setValue={setValue}
							duration={services.find(ser => ser.name === selectedService)?.duration as string}
							selectedTimeSlot={selectedTimeSlot as string}
							selectedDate={appointmentDate as Date}
						/>
					)}
				</div>
				{selectedService && errors?.timeSlot && (
					<p className="text-xs mt-1 text-red-600">{errors.timeSlot.message}</p>
				)}
				<Button className="max-lg:hidden lg:mt-2 lg:font-bold">
					{t('companyDetails.makeAppointment')}
				</Button>
			</div>
		</div>
	)
}

export const MakeBookingForm = ({
	categories,
	services,
	register,
	errors,
	watch,
	company,
	filteredServices,
	setFilteredServices,
	logo,
	categorySelected,
	serviceSelected,
	setValue
}: FormProps & {
	company: Company
	logo: string
	categorySelected: string
	filteredServices: Service[]
	setFilteredServices: (value: Service[]) => void
	categories: CompanyCategory[]
	services: Service[]
	serviceSelected: string
}) => {
	const { t } = useTranslation()
	const tKey = getTKey('appointment.create')
	const selectedTimeSlot = watch?.('timeSlot')
	const selectedCategory = watch?.('category')
	const selectedService = watch?.('services')
	const appointmentDate = watch?.('appointmentDate')
	const employeeId = watch?.('id_employee')

	useEffect(() => {
		setTimeout(() => setValue?.('services', serviceSelected), 500)
	}, [])

	useEffect(() => {
		if (selectedCategory) {
			setValue?.('services', undefined as any)
		}
	}, [selectedCategory])

	useEffect(() => {
		if (categorySelected && serviceSelected) {
			setValue?.('category', categorySelected)
		}
	}, [categorySelected, serviceSelected])

	useEffect(() => {
		if (selectedCategory) {
			const servicesByCategory = services.filter(
				s => s.id_category === categories.find(c => c.category_name === selectedCategory)?._id
			)
			setFilteredServices(servicesByCategory)
		}
	}, [selectedCategory])

	const [serviceSchedule, setServiceSchedule] = useState<Schedule[]>()

	useEffect(() => {
		if (selectedService && services.length > 0) {
			service
				.getServiceSchedule(services.find(ser => ser.name === selectedService)?._id as string)
				.then(res => setServiceSchedule(res))
		}
	}, [selectedService, services])

	const timeSlots = useMemo(() => {
		const mergedTime: Record<number, string[]> = {}
		setValue?.('timeSlot', '')
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') continue

			const timeRange = getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				false,
				services.find(ser => ser.name === selectedService)?.duration as string,
				schedule.day,
				Number(services.find(service => service.name === selectedService)?.min_booking) / 60
			)

			if (timeRange) {
				Object.entries(timeRange).forEach(([key, value]) => {
					const day = parseInt(key)
					if (!mergedTime[day]) {
						mergedTime[day] = []
					}
					mergedTime[day].push(...value)
				})
			}
		}

		return mergedTime
	}, [serviceSchedule])

	const todayTimeSlots = useMemo(() => {
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') return
			return getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				true,
				services.find(ser => ser.name === selectedService)?.duration as string,
				DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday,
				Number(services.find(service => service.name === selectedService)?.min_booking) / 60
			)
		}
	}, [serviceSchedule])

	return (
		<div className="flex flex-col gap-y-3.5">
			<div className="lg:hidden lg:px-5 pb-5 border-b border-[#D3E3F1] flex gap-x-2.5 items-center">
				<img src={logo} className="w-[65px] h-[61px] rounded-lg object-cover" />
				<div className="flex flex-col gap-y-0.5">
					<h2 className="font-domine text-primary font-bold">{company?.company_name}</h2>
					<div className="flex">
						{company?.company_type?.slice(0, 2).map((item, index) => (
							<div key={item} className="flex items-center">
								{index > 0 && <div className="w-[10px] h-[10px] bg-[#D9D9D9] rounded-full mx-3" />}
								<span className="text-secondary text-sm font-semibold">{item}</span>
							</div>
						))}
					</div>
				</div>
			</div>
			<h2 className="text-primary text-[20px] font-bold max-lg:hidden">
				{t('companyDetails.makeAppointment')}
			</h2>
			<div className="pt-3 max-lg:px-0 flex flex-col gap-y-4 rounded-xl">
				<Select
					className="w-full text-primary rounded border-[#D3E3F1] max-lg:font-normal lg:font-normal"
					register={register}
					errors={errors}
					name="category">
					<option value="">{t(tKey('labels.category'))}</option>
					{categories?.map(category => (
						<option key={category._id} value={category.category_name}>
							{category.category_name}
						</option>
					))}
				</Select>
				<Select
					className="w-full text-primary rounded border-[#D3E3F1] max-lg:font-normal lg:font-normal"
					name="services"
					register={register}
					errors={errors}>
					<option value="">{t(tKey('labels.services'))}</option>
					{selectedCategory &&
						filteredServices.map(service => (
							<option key={service._id} value={service.name}>
								{service.name}
							</option>
						))}
				</Select>
				{selectedService &&
					(filteredServices.find(service => service.name === selectedService)?.id_employees
						.length as number) > 0 && (
						<Select
							className="w-full text-primary rounded border-[#D3E3F1] max-lg:font-normal lg:font-normal"
							name="id_employee"
							register={register}
							errors={errors}>
							<option value="">{t(tKey('labels.employee'))}</option>
							{selectedService &&
								filteredServices
									.find(service => service.name === selectedService)
									?.id_employees.map(employee => (
										<option key={employee._id} value={employee._id}>
											{employee.fname + ' ' + employee.lname}
										</option>
									))}
						</Select>
					)}
				<div className="relative z-20 flex items-center">
					{selectedService && (
						<TimePicker
							companyId={company._id}
							duration={services.find(ser => ser.name === selectedService)?.duration as string}
							timeSlots={timeSlots as { [day: number]: string[] }}
							todayTimeSlots={todayTimeSlots as { [day: number]: string[] }}
							setValue={setValue}
							employeeId={employeeId}
							selectedTimeSlot={selectedTimeSlot as string}
							selectedDate={appointmentDate as Date}
						/>
					)}
				</div>
				{selectedService && errors?.timeSlot && (
					<p className="text-xs mt-1 text-red-600">{errors.timeSlot.message}</p>
				)}
				<Button className="mt-4 self-end !text-sm lg:font-bold">
					{t('companyDetails.makeAppointment')}
				</Button>
			</div>
		</div>
	)
}

const UserVerificationForm = ({
	register,
	isLoading,
	errors,
	reset,
	control,
	watch,
	setValue,
	resetField,
	selectedTab,
	otpVisible,
	setOtpVisible,
	pin,
	setPin,
	phonenumber,
	sendOtp,
	setSelectedTab
}: FormProps & {
	selectedTab: string
	otpVisible: boolean
	pin: string
	phonenumber: string
	isLoading: { register: boolean; login: boolean; otp: boolean }
	setOtpVisible: (val: boolean) => void
	sendOtp: () => void
	setPin: (val: string) => void
	setSelectedTab: (val: string) => void
}) => {
	const { t } = useTranslation()
	const tKey = getTKey('appointment.create')

	useEffect(() => {
		setOtpVisible(false)
		reset?.()
		resetField?.('password')
	}, [selectedTab])

	return (
		<div
			className={clsx('flex flex-col gap-y-8', {
				'h-[357px] max-lg:h-[406px]': selectedTab === 'selection'
			})}>
			{selectedTab !== 'selection' && (
				<Tabs
					tabs={['login', 'signup']}
					selectedTab={selectedTab as string}
					setTab={setSelectedTab}
					variant="pill"
				/>
			)}
			{selectedTab === 'selection' && !otpVisible && (
				<div className="relative grow overflow-hidden grid auto-cols-auto lg:grid-flow-col rounded-xl lg:py-8 lg:px-6 items-center place-items-center bg-[#F5F7FA]">
					<div className="flex items-center flex-col py-3 lg:px-3.5 pr-3.5 gap-y-10">
						<div className="flex flex-col items-center gap-y-3 text-center">
							<h3 className="capitalize text-primary text-lg lg:text-[20px] font-bold font-domine">
								{t(tKey('headings.signIn'))}
							</h3>
							<p className="text-[#7F9AB2] max-lg:text-sm">{t(tKey('headings.welcomeBack'))}</p>
						</div>
						<Button onClick={() => setSelectedTab('login')} className="rounded-full z-50">
							{t(tKey('headings.login'))}
						</Button>
					</div>
					<div className="max-lg:border-b lg:border-r max-lg:w-11/12 max-lg:h-2 border-dashed border-primary h-full w-2" />
					<div className="flex flex-col items-center gap-y-10 py-3 lg:pl-3.5 lg:px-3.5">
						<div className="flex flex-col items-center gap-y-3 text-center">
							<h3 className="capitalize text-primary text-lg lg:text-[20px] font-bold font-domine">
								{t(tKey('headings.newToHealthCorner'))}
							</h3>
							<p className="text-[#7F9AB2] max-lg:text-sm">{t(tKey('headings.getStarted'))}</p>
						</div>
						<Button onClick={() => setSelectedTab('signup')} className="rounded-full z-50">
							{t(tKey('headings.signUp'))}
						</Button>
					</div>
					<img src={EllipseRightIcon} className="absolute -left-20 lg:-left-10 max-lg:h-[300px]" />
					<img src={EllipseBottomIcon} className="absolute -right-16 -top-20 max-lg:hidden" />
					<img
						src={EllipseRightIcon}
						className="-scale-x-100 absolute -right-20 lg:hidden h-[300px]"
					/>
				</div>
			)}
			{selectedTab === 'login' && !otpVisible && (
				<Login
					showHighlights={false}
					showSubmitButton={false}
					register={register}
					control={control}
					errors={errors}
					watch={watch}
					setValue={setValue}
					isLoading={isLoading.login}
					openforgotPassword
				/>
			)}
			{selectedTab === 'signup' && !otpVisible && (
				<Register
					showHighlights={false}
					showSubmitButton={false}
					register={register}
					watch={watch}
					control={control}
					isLoading={isLoading.register}
					errors={errors}
				/>
			)}
			{otpVisible && (
				<Otp
					pin={pin}
					onChange={(value: string) => setPin(value)}
					onResendOtp={sendOtp}
					phoneNumber={phonenumber}
					isLoading={isLoading.otp}
					showHighlights={false}
					showSubmitButton={false}
				/>
			)}
		</div>
	)
}

const IdentityForm = ({
	register,
	relations,
	errors,
	control,
	watch,
	setImage,
	setRelatedPersonId
}: FormProps & {
	relations: Relation[]
	setImage: (file: File) => void
	setRelatedPersonId: (value: string) => void
}) => {
	const { t } = useTranslation()
	const user = useAppSelector(state => state.user)
	const tKey = getTKey('appointment.create')

	const relatedPersonValue = watch?.('relatedPerson')

	useEffect(() => {
		if (relatedPersonValue) {
			setRelatedPersonId(relatedPersonValue)
		}
	}, [relatedPersonValue])

	const disabled = relatedPersonValue !== 'Myself' && relatedPersonValue !== 'other'

	return (
		<div className="flex flex-col gap-y-8">
			<div className="flex flex-col gap-y-6">
				{relatedPersonValue === 'Myself' && (
					<ImageUpload
						image={user?.profile_url === 'crm' ? ProfilePhotoIcon : user?.profile_url}
						onUpload={(file: File) => setImage(file)}
						name="user-profile"
					/>
				)}
				<SelectLabel
					register={register}
					errors={errors}
					defaultValue="Myself"
					name="relatedPerson"
					labelText={t(tKey('labels.patient'))}>
					<option value="Myself">{t(tKey('labels.myself'))}</option>
					{relations?.map(relation => (
						<option key={relation._id}>{relation.fname + ' ' + relation.lname}</option>
					))}
					<option value="other">{t(tKey('labels.others'))}</option>
				</SelectLabel>
				<h2 className="text-primary font-domine font-bold text-base lg:text-lg">
					{t(tKey('headings.basicInfo'))}
				</h2>
				<div className="flex flex-col gap-y-5">
					<Controller
						control={control}
						name="gender"
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-col">
								<div className="flex flex-row gap-x-3">
									<RadioInput
										labelText={t(tKey('labels.male'))}
										disabled={disabled}
										onChange={onChange}
										name="gender"
										register={register}
										value="male"
										checked={value === 'male'}
									/>
									<RadioInput
										labelText={t(tKey('labels.female'))}
										disabled={disabled}
										onChange={onChange}
										register={register}
										name="gender"
										value="female"
										checked={value === 'female'}
									/>
								</div>
								{errors?.gender && (
									<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
								)}
							</div>
						)}
					/>
					<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
						<LabelInput
							disabled={disabled}
							register={register}
							errors={errors}
							name="fname"
							labelText={t(tKey('labels.firstName'))}
						/>
						<LabelInput
							disabled={disabled}
							register={register}
							errors={errors}
							name="lname"
							labelText={t(tKey('labels.lastName'))}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-y-5 gap-x-5">
						<Controller
							control={control}
							name="insuranceNumber"
							render={({ field: { onChange, value } }) => (
								<InputNumber
									onChange={onChange}
									disabled={disabled}
									value={value}
									errors={errors}
									register={register}
									maxLength={20}
									name="insuranceNumber"
									labelText={t(tKey('labels.insuranceNo'))}
									type="number"
								/>
							)}
						/>
						<div className="relative z-20 grow flex items-center">
							<LabelInput
								name="birthDate"
								register={register}
								errors={errors}
								disabled={disabled}
								labelText={t(tKey('labels.dob'))}
								type="date"
							/>
							<div
								className={clsx('absolute -z-20 right-3', {
									'-translate-y-2.5': errors?.birthDate
								})}>
								<img src={InputCalendarIcon} className=" cursor-pointer" />
							</div>
						</div>
					</div>
					{relatedPersonValue === 'Myself' ? (
						<div className="grid grid-cols-1 gap-y-5 lg:grid-cols-2 gap-x-5">
							<LabelInput
								register={register}
								errors={errors}
								disabled={disabled}
								labelText={t(tKey('labels.pID'))}
								name="pID"
							/>
						</div>
					) : (
						<div className="grid grid-cols-1 gap-y-5 lg:grid-cols-2 gap-x-5">
							<LabelInput
								register={register}
								disabled={disabled}
								errors={errors}
								labelText="Relation"
								name="relation"
							/>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col gap-y-6">
				<h2 className="text-primary font-domine font-bold text-base lg:text-lg">
					{t(tKey('headings.patientAddress'))}
				</h2>
				<div className="flex flex-col gap-y-5">
					<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
						<LabelInput
							register={register}
							errors={errors}
							labelText={t(tKey('labels.street'))}
							name="address"
						/>
						<Controller
							control={control}
							name="streetNumber"
							render={({ field: { onChange, value } }) => (
								<InputNumber
									disabled={disabled}
									onChange={onChange}
									value={value}
									errors={errors}
									register={register}
									labelText={t(tKey('labels.streetNo'))}
									name="streetNumber"
								/>
							)}
						/>
					</div>
					<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
						<Controller
							control={control}
							name="zip"
							render={({ field: { onChange, value } }) => (
								<InputNumber
									onChange={onChange}
									disabled={disabled}
									value={value}
									register={register}
									errors={errors}
									labelText={t(tKey('labels.zipCode'))}
									name="zip"
								/>
							)}
						/>
						<LabelInput
							disabled={disabled}
							register={register}
							errors={errors}
							labelText={t(tKey('labels.city'))}
							name="city"
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5">
						<LabelInput
							disabled={disabled}
							register={register}
							errors={errors}
							labelText={t(tKey('labels.country'))}
							name="country"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const QuestionsForm = ({
	services,
	additionalInfo,
	setAdditionalInfo,
	answeredQuestions,
	setAnsweredQuestions
}: FormProps & {
	answeredQuestions: any
	additionalInfo: string
	setAdditionalInfo: (value: string) => void
	setAnsweredQuestions: Dispatch<
		SetStateAction<{ [questions: string]: { value: string; group: string } }>
	>
	services: Service[]
}) => {
	const { t } = useTranslation()
	const tKey = getTKey('appointment.create')

	const appointmentData = useAppSelector(state => state.appointment.visitReasonForm)

	const [serviceId, setServiceId] = useState<string>()
	const [healthQuestions, setHealthQuestions] = useState<AppointmentQuestion[]>([])
	const [symptomQuestions, setSymptomQuestions] = useState<AppointmentQuestion[]>([])

	useEffect(() => {
		const serviceId = services.find(ser => ser.name === appointmentData.services)?._id
		setServiceId(serviceId)
	}, [appointmentData])

	useEffect(() => {
		if (serviceId) {
			appointmentService.getAppointmentQuestions(serviceId).then(res => {
				setHealthQuestions(res)
			})
		}
	}, [serviceId])

	const handleChange = (event: React.ChangeEvent<any>, question: string, group?: string) => {
		setAnsweredQuestions((prev: any) => ({
			...prev,
			[question
				.replace('?', '')
				.replace(/\s*\([^)]*\)/, '')
				.trim()]: { value: event.target.value, group: group }
		}))
	}

	return (
		<div className="flex flex-col gap-y-6">
			{healthQuestions.length > 0 && (
				<h1 className="text-primary text-xl font-bold pb-4 border-b border-[#D3E3F1]">
					{t(tKey('headings.healthQuestions'))}
				</h1>
			)}
			{healthQuestions
				.filter(question => question.is_active === 'aktiv')
				.sort((a, b) => a.order_num - b.order_num)
				.map(question => (
					<div
						key={question._id}
						className="flex max-lg:py-3 flex-col gap-y-4 max-lg:border-b max-lg:border-[#D3E3F1]">
						<h3 className="text-base lg:text-lg text-primary font-domine">{question.label}</h3>
						{question.type === 'radio' && (
							<div className="flex flex-row gap-x-3">
								<div className={clsx('flex items-center space-x-2')}>
									<input
										name={question.label}
										type="radio"
										required={question.is_required === 'yes'}
										onChange={e => handleChange(e, question.label, question.group)}
										value="yes"
										checked={
											answeredQuestions[
												question.label
													.replace('?', '')
													.replace(/\s*\([^)]*\)/, '')
													.trim()
											]?.value === 'yes'
										}
										className="w-4 h-4 ring-0 checked:ring-0 text-[#61BC5F] border border-[#D3E3F1]"
									/>
									<p className="text-[#7F9AB2]">{t(tKey('labels.yes'))}</p>
								</div>
								<div className={clsx('flex items-center space-x-2')}>
									<input
										name={question.label}
										type="radio"
										required={question.is_required === 'yes'}
										onChange={e => handleChange(e, question.label, question.group)}
										value="no"
										checked={
											answeredQuestions[
												question.label
													.replace('?', '')
													.replace(/\s*\([^)]*\)/, '')
													.trim()
											]?.value === 'no'
										}
										className="w-4 h-4 ring-0 checked:ring-0 text-[#61BC5F] border border-[#D3E3F1]"
									/>
									<p className="text-[#7F9AB2]">{t(tKey('labels.no'))}</p>
								</div>
							</div>
						)}

						{question.type === 'select' && (
							<Select
								className="w-full lg:font-normal max-lg:font-normal"
								required={question.is_required === 'yes'}
								onChange={e => handleChange(e, question.label, question.group)}
								name={question.label}>
								<option value="">{question.label}</option>
								{question.options.map(option => (
									<option key={option} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						)}

						{question.type === 'textarea' && (
							<textarea
								onChange={e => handleChange(e, question.label, question.group)}
								required={question.is_required === 'yes'}
								name={question.label}
								placeholder={question.label}
								className="w-full rounded font-normal pl-4 bg-white focus:ring-0 border focus:border-[#D3E3F1] border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm lg:placeholder:text-base focus:outline-none lg:text-md text-sm"
							/>
						)}

						{question.type !== 'select' &&
							question.type !== 'radio' &&
							question.type !== 'textarea' && (
								<LabelInput
									name={question.label}
									onChange={e => handleChange(e, question.label, question.group)}
									type={question.input_type}
									required={question.is_required === 'yes'}
								/>
							)}
					</div>
				))}
			{symptomQuestions.length > 0 && (
				<h1 className="text-primary text-xl font-bold pb-4 border-b border-[#D3E3F1]">
					{t(tKey('headings.symptoms'))}
				</h1>
			)}
			{symptomQuestions
				.filter(question => question.is_active === 'aktiv')
				.sort((a, b) => a.order_num - b.order_num)
				.map(question => (
					<div
						key={question._id}
						className="flex max-lg:py-3 flex-col gap-y-4 max-lg:border-b max-lg:border-[#D3E3F1]">
						<h3 className="text-base lg:text-lg text-primary font-domine">{question.label}</h3>
						{question.type === 'radio' && (
							<div className="flex flex-row gap-x-3">
								<div className={clsx('flex items-center space-x-2')}>
									<input
										name={question.label}
										type="radio"
										required={question.is_required === 'yes'}
										onChange={e => handleChange(e, question.label, question.group)}
										value="yes"
										checked={
											answeredQuestions[
												question.label
													.replace('?', '')
													.replace(/\s*\([^)]*\)/, '')
													.trim()
											]?.value === 'yes'
										}
										className="w-4 h-4 ring-0 checked:ring-0 text-[#61BC5F] border border-[#D3E3F1]"
									/>
									<p className="text-[#7F9AB2]">Yes</p>
								</div>
								<div className={clsx('flex items-center space-x-2')}>
									<input
										name={question.label}
										type="radio"
										required={question.is_required === 'yes'}
										onChange={e => handleChange(e, question.label, question.group)}
										value="no"
										checked={
											answeredQuestions[
												question.label
													.replace('?', '')
													.replace(/\s*\([^)]*\)/, '')
													.trim()
											]?.value === 'no'
										}
										className="w-4 h-4 ring-0 checked:ring-0 text-[#61BC5F] border border-[#D3E3F1]"
									/>
									<p className="text-[#7F9AB2]">No</p>
								</div>
							</div>
						)}

						{question.type === 'select' && (
							<Select
								className="w-full lg:font-normal max-lg:font-normal"
								required={question.is_required === 'yes'}
								onChange={e => handleChange(e, question.label, question.group)}
								name={question.label}>
								<option value="">{question.label}</option>
								{question.options.map(option => (
									<option key={option} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						)}

						{question.type === 'textarea' && (
							<textarea
								onChange={e => handleChange(e, question.label, question.group)}
								required={question.is_required === 'yes'}
								placeholder={question.label}
								name={question.label}
								className="w-full rounded font-normal pl-4 bg-white focus:ring-0 border focus:border-[#D3E3F1] border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm lg:placeholder:text-base focus:outline-none lg:text-md text-sm"
							/>
						)}

						{question.type !== 'select' &&
							question.type !== 'radio' &&
							question.type !== 'textarea' && (
								<LabelInput
									name={question.label}
									onChange={e => handleChange(e, question.label, question.group)}
									type={question.input_type}
									required={question.is_required === 'yes'}
								/>
							)}
					</div>
				))}
			<h1 className="text-primary text-xl font-bold pb-4 border-b border-[#D3E3F1]">
				{t(tKey('headings.additionalInformation'))}
			</h1>
			<textarea
				onChange={e => setAdditionalInfo(e.target.value)}
				value={additionalInfo}
				name="additionalInformation"
				placeholder={t(tKey('headings.additionalInformation'))}
				className="w-full rounded font-normal pl-4 bg-white focus:ring-0 border focus:border-[#D3E3F1] border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm lg:placeholder:text-base focus:outline-none lg:text-md text-sm"
			/>
		</div>
	)
}

interface ConfirmAppointmentForm {
	company: Company
	register: UseFormRegister<AppointmentForm>
	errors: FieldErrors<AppointmentForm>
	services: Service[]
	relation: Relation
	additionalInfo: string
	logo: string
}
const ConfirmAppointmentForm = ({
	register,
	errors,
	services,
	relation,
	additionalInfo,
	company,
	logo
}: ConfirmAppointmentForm) => {
	const { t } = useTranslation()
	const [searchParams, _setSearchParams] = useSearchParams()
	const companyId = searchParams.get('id')

	const appLanguage = getAppLang()
	const appointmentData = useAppSelector(state => state.appointment.visitReasonForm)
	const selectedService = services.find(ser => ser.name === appointmentData.services)

	return (
		<div className="lg:min-w-[906px] flex flex-col gap-y-5 lg:gap-y-8">
			<div className="max-lg:hidden pb-5 border-b border-[#D3E3F1] flex gap-x-3">
				<img src={logo} className="w-[76px] h-[76px] object-cover rounded-lg" />
				<div className="flex flex-col gap-y-2 grow">
					<h4 className="font-domine text-primary text-[20px] font-bold">{company.company_name}</h4>
					<div className="flex justify-between items-center">
						<div className="flex">
							{company.company_type?.slice(0, 2).map((item, index) => (
								<div key={item} className="flex items-center">
									{index > 0 && (
										<div className="w-[10px] h-[10px] bg-[#D9D9D9] rounded-full mx-3" />
									)}
									<span className="text-secondary font-semibold">{item}</span>
								</div>
							))}
						</div>
						<div className="flex gap-x-2 items-center whitespace-nowrap">
							<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
							<p className="text-sm text-secondary">
								{DateTime.fromISO(appointmentData.appointmentDate as any)
									.setLocale(appLanguage)
									.toFormat('cccc, dd. MMM yyyy')}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none">
								<path
									d="M10.4993 18.3327C15.1017 18.3327 18.8327 14.6017 18.8327 9.99935C18.8327 5.39698 15.1017 1.66602 10.4993 1.66602C5.89698 1.66602 2.16602 5.39698 2.16602 9.99935C2.16602 14.6017 5.89698 18.3327 10.4993 18.3327Z"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.5 5V10L13.8333 11.6667"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p className="text-sm text-secondary">
								{appointmentData.timeSlot} -{' '}
								{DateTime.fromFormat(appointmentData.timeSlot, 'HH:mm')
									.plus({
										minutes: Number(
											services.find(s => s.name === appointmentData.services)?.duration ?? 0
										)
									})
									.toFormat('HH:mm')}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="lg:hidden pb-5 border-b border-[#D3E3F1] flex flex-col gap-y-2.5">
				<div className="flex gap-x-[7px] items-center">
					<img src={logo} className="w-[65px] h-[61px] rounded-lg" />
					<h4 className="font-domine text-primary text-lg font-bold">{company.company_name}</h4>
				</div>
				<div className="flex flex-col gap-y-2 grow">
					<div className="flex justify-between items-center">
						<div className="flex gap-x-2 items-center whitespace-nowrap">
							<img src={InputCalendarIcon} className="w-[24px] h-[24px]" />
							<p className="text-sm text-secondary">
								{DateTime.fromISO(appointmentData.appointmentDate as any)
									.setLocale(appLanguage)
									.toFormat('cccc, dd. MMM yyyy')}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none">
								<path
									d="M10.4993 18.3327C15.1017 18.3327 18.8327 14.6017 18.8327 9.99935C18.8327 5.39698 15.1017 1.66602 10.4993 1.66602C5.89698 1.66602 2.16602 5.39698 2.16602 9.99935C2.16602 14.6017 5.89698 18.3327 10.4993 18.3327Z"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.5 5V10L13.8333 11.6667"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p className="text-sm text-secondary">
								{appointmentData.timeSlot} -{' '}
								{DateTime.fromFormat(appointmentData.timeSlot, 'HH:mm')
									.plus({
										minutes: Number(
											services.find(s => s.name === appointmentData.services)?.duration ?? 0
										)
									})
									.toFormat('HH:mm')}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-y-3.5">
				<h4 className="font-domine lg:text-lg font-bold text-primary">
					{t('companyDetails.services')}
				</h4>
				<div className="flex gap-x-3.5">
					<div className="px-3 py-0.5 rounded-full bg-[#D3E3F180] text-secondary">
						{appointmentData.services}
					</div>
				</div>
			</div>

			{appointmentData.id_employee && (
				<div className="flex flex-col gap-y-3.5">
					<h4 className="font-domine text-lg font-bold text-primary">
						{t('companyDetails.employee')}
					</h4>
					<p className="text-secondary">
						{selectedService?.id_employees.find(emp => emp._id === appointmentData.id_employee)
							?.fname +
							' ' +
							selectedService?.id_employees.find(emp => emp._id === appointmentData.id_employee)
								?.lname}
					</p>
				</div>
			)}
			<div className="flex flex-col gap-y-3.5">
				<h4 className="font-domine text-lg font-bold text-primary">
					{t('companyDetails.description')}
				</h4>
				<div className="flex gap-x-3.5">
					<div className="text-secondary">
						{services.find(s => s.name === appointmentData.services)?.description}
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-y-3.5">
				<h4 className="font-domine text-lg font-bold text-primary">
					{t('appointment.paymentMethod')}
				</h4>
				<div className="flex gap-x-3.5">
					<div className="text-secondary">{t('appointment.onsite')}</div>
				</div>
			</div>

			<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
				<h4 className="font-domine lg:text-lg font-bold text-primary">
					{t('userDashboard.labels.bookingFor')}
				</h4>
				<p className="text-secondary">{relation?.relation ?? 'Myself'}</p>
			</div>

			<div className="flex flex-col lg:gap-y-3.5 gap-y-2">
				<h4 className="font-domine lg:text-lg font-bold text-primary">
					{t('appointment.additionalInformation')}
				</h4>

				<p className="text-secondary">
					{additionalInfo ? additionalInfo : t('appointment.noAdditionalInformation')}
				</p>
			</div>

			<div className="flex flex-col gap-y-5">
				<h4 className="font-domine lg:text-lg font-bold text-primary">
					{t('companyDetails.contactDetails')}
				</h4>
				<div className="flex flex-col gap-y-4">
					<div className="flex gap-x-2 max-lg:items-center">
						<img src={OutlinePhoneIcon} className="max-lg:h-6 max-lg:w-6" />
						<p className="text-primary max-lg:text-sm">{company.phone}</p>
					</div>
					<div className="flex gap-x-2 max-lg:items-center">
						<img src={OutlineMailIcon} className="max-lg:h-6 max-lg:w-6" />
						<p className="text-primary max-lg:text-sm">{company.email}</p>
					</div>
					<div className="flex gap-x-2 max-lg:items-center">
						<img src={OutlineGlobeIcon} className="max-lg:h-6 max-lg:w-6" />
						<p className="text-primary max-lg:text-sm">{company.website}</p>
					</div>
					<div className="flex gap-x-2 max-lg:items-center">
						<img src={OutlineLocationIcon} className="max-lg:h-6 max-lg:w-6" />
						<p className="text-primary max-lg:text-sm">{`${company?.address} ${company?.address_no}, ${company?.zip} ${company?.city}`}</p>
					</div>
				</div>
			</div>
			<div className="py-[15px] lg:px-[131px] bg-[#D3E3F14D] text-primary items-center justify-center flex flex-col gap-y-2">
				<span className="lg:text-sm">Total</span>
				<span className="font-bold font-domine lg:text-sm">
					{calculateServicePrice(
						services.find(s => s.name === appointmentData.services)?.price.toFixed(2) as string,
						services.find(s => s.name === appointmentData.services)?.health_insurance === '1'
					)}
				</span>
			</div>
			<div>
				<div className="flex gap-x-3 items-start">
					<input
						type="checkbox"
						{...register('acceptAgb')}
						className="p-2 appearance-none text-main focus:outline-none focus:ring-transparent border-black text-[#61BC5F] text-base"
					/>
					<label className="font-medium text-secondary xl:whitespace-nowrap">
						{t('auth.register.labels.agree')}{' '}
						<Link
							className="cursor-pointer underline text-[#61BC5F]"
							to={`/company/${companyId}/agb`}
							target="__blank">
							{t('auth.register.labels.terms')}
						</Link>{' '}
						{t('auth.register.labels.of')} {company.company_name}
					</label>
				</div>
				{errors.acceptAgb && <p className="text-xs text-red-500">{errors.acceptAgb.message}</p>}
			</div>
		</div>
	)
}

interface PaymentOptionFormProps {
	selectedPayment: string
	setSelectedPayment: (value: string) => void
}

const PaymentOptionForm = ({ selectedPayment, setSelectedPayment }: PaymentOptionFormProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('appointment.create')
	return (
		<div className="flex flex-col gap-y-6 lg:gap-y-10">
			<div className="flex flex-col gap-y-2">
				<h1 className="font-domine lg:text-[22px] font-bold text-primary ">
					{t(tKey('headings.paymentMethods'))}
				</h1>
				<p className="text-secondary max-lg:text-xs">{t(tKey('labels.selectMethod'))}</p>
			</div>
			<div className="flex flex-col gap-y-4 lg:gap-y-6">
				<InputRadioGroup
					label="payment-option"
					options={[
						{
							label: t(tKey('labels.payOnVisit')),
							helperText: t(tKey('labels.settlePayment')),
							value: 'onsite',
							icon: CashIcon
						},
						{
							label: t(tKey('labels.payOnline')),
							helperText: t(tKey('labels.secureBooking')),
							value: 'online',
							icon: OnlineIcon
						}
					]}
					selectedOption={selectedPayment}
					onChange={setSelectedPayment}
				/>
			</div>
			<div className="flex gap-x-2.5 items-center border border-[#92400E33] bg-[#FFFBEB] p-2 rounded">
				<InformationCircleIcon className="lg:h-4 lg:w-4 h-3 w-3 text-[#92400E]" />
				<p className="text-sm text-[#92400E]">{t(tKey('labels.payOnlineAlert'))}</p>
			</div>
		</div>
	)
}
