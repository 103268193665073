import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'

import clsx from 'clsx'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Highlights } from 'components/auth/highlights'
import { LabelInput } from 'components/inputs/input'
import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'

interface ResetPasswordProps {
	register: UseFormRegister<Auth>
	errors: FieldErrors<Auth>
	isLoading: boolean
}

export const ResetPassword = ({ register, errors, isLoading }: ResetPasswordProps) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)

	const { t } = useTranslation()
	const tKey = getTKey('auth')

	return (
		<div>
			<h2 className="font-domine text-[#123258] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
				{t(tKey('resetPassword.title.resetPassword'))}
			</h2>
			<p className="text-secondary font-nunito text-base font-normal leading-6 pb-4 2xl:pb-8 max-lg:text-center">
				{t(tKey('resetPassword.title.resetYourPassword'))}
			</p>
			<div className="max-lg:px-5">
				<div className="flex flex-col gap-y-5">
					<div>
						<div className="relative z-20 flex items-center">
							<LabelInput
								type={isPasswordVisible ? 'text' : 'password'}
								register={register}
								autoCapitalize="false"
								autoCorrect="off"
								autoComplete="new-password"
								name="password"
								labelText={t(tKey('resetPassword.labels.Password'))}
							/>
							<div
								onClick={() => setIsPasswordVisible(!isPasswordVisible)}
								className={clsx('absolute right-4', {
									'-translate-y-2.5': errors.password
								})}>
								{isPasswordVisible ? (
									<EyeIcon className="w-6 h-6 stroke-primary" />
								) : (
									<EyeSlashIcon className="w-6 h-6 stroke-primary" />
								)}
							</div>
						</div>
						{errors?.password && (
							<p className="text-xs text-red-500">{errors.password.message as string}</p>
						)}
					</div>

					<div>
						<div className="relative z-20 flex items-center">
							<LabelInput
								type={isConfirmPasswordVisible ? 'text' : 'password'}
								register={register}
								autoCapitalize="false"
								autoCorrect="off"
								autoComplete="new-password"
								name="confirmPassword"
								labelText={t(tKey('resetPassword.labels.ConfirmPassword'))}
							/>
							<div
								onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
								className={clsx('absolute right-4', {
									'-translate-y-2.5': errors.confirmPassword
								})}>
								{isConfirmPasswordVisible ? (
									<EyeIcon className="w-6 h-6 stroke-primary" />
								) : (
									<EyeSlashIcon className="w-6 h-6 stroke-primary" />
								)}
							</div>
						</div>
						{errors?.confirmPassword && (
							<p className="text-xs text-red-500">{errors.confirmPassword.message as string}</p>
						)}
					</div>

					<div className="flex justify-center my-6">
						<Button
							className="bg-primary-green whitespace-nowrap text-white w-3/5 py-3 px-0 capitalize text-sm"
							type="submit"
							disabled={isLoading}>
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('resetPassword.labels.pleaseWait'))}.
									</span>
								</div>
							) : (
								<span>{t(tKey('resetPassword.labels.ConfirmPassword'))}</span>
							)}
						</Button>
					</div>
				</div>
			</div>
			<div className="max-lg:pt-8">
				<Highlights />
			</div>
		</div>
	)
}
