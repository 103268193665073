import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { LabelInput } from 'components/inputs/input'
import { ToggleSwitch } from 'components/inputs/toggle'
import { useAppDispatch } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { useState } from 'react'
import userService from 'services/user-service'
import { logOut } from 'slices/auth'
import { getTKey } from 'utils/language'

export const ChangePassword = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const isMobile = useIsMobile()
	const tKey = getTKey('auth')

	const [showDeactivateModal, setShowDeactivateModal] = useState(false)
	const [isDeactivating, setIsDeactivating] = useState(false)

	const schema = yup.object({
		oldPassword: yup.string().when('$step', {
			is: false,
			then: schema => schema.required(t(tKey('errors.oldPassword')))
		}),
		password: yup.string().when('$step', {
			is: true,
			then: schema => schema.required(t(tKey('errors.loginPassword')))
		}),
		newPassword: yup.string().when('$step', {
			is: false,
			then: schema =>
				schema
					.required(t(tKey('errors.newPassword')))
					.matches(
						/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
						t(tKey('errors.validPassword'))
					)
					.min(8, t(tKey('errors.minPassword')))
		}),
		confirmPassword: yup.string().when('$step', {
			is: false,
			then: schema =>
				schema
					.required(t(tKey('errors.confirmPassword')))
					.oneOf([yup.ref('newPassword'), ''], t(tKey('errors.matchPassword')))
		})
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<any>({
		resolver: yupResolver(schema as any),
		context: { step: showDeactivateModal },
		mode: 'all'
	})

	const onSubmit = (data: any) => {
		if (showDeactivateModal) {
			setIsDeactivating(true)
			return userService
				.deactivateAccount(data.password)
				.then(() => {
					dispatch(logOut())
				})
				.catch(err => toast.error(err?.response?.data?.message))
				.finally(() => setIsDeactivating(false))
		}
		userService
			.updateUserPassword(data.oldPassword, data.newPassword)
			.then(() => {
				toast.success(t(tKey('toast.passwordSuccess')))
				reset()
			})
			.catch(err => toast.error(t(tKey('toast.invalidPassword'))))
	}

	return (
		<AppLayout renderDashboardHeader>
			{showDeactivateModal && (
				<Modal
					onClose={() => setShowDeactivateModal(false)}
					isFullHeight
					width="w-[500px]"
					showCrossIcon={true}
					noPadding>
					<AppLayout renderDashboardHeader={isMobile}>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="flex flex-col py-[50px] px-[24px] gap-y-5">
							<h3 className="lg:text-lg mb-4 text-center text-sm text-primary font-bold">
								{t(tKey('changePassword.enterPassword'))}
							</h3>
							<LabelInput
								register={register}
								errors={errors}
								type="password"
								name="password"
								labelText={t(tKey('changePassword.labels.password'))}
							/>
							<Button className="lg:w-fit lg:ml-auto">
								{isDeactivating ? (
									<div className="flex items-center justify-center gap-x-5">
										<Spinner />
										<span className="animate-pulse whitespace-nowrap">
											{t(tKey('forgotPassword.labels.pleaseWait'))}
										</span>
									</div>
								) : (
									<span>{t(tKey('changePassword.deactivateAccount'))}</span>
								)}
							</Button>
						</form>
					</AppLayout>
				</Modal>
			)}
			<div className="py-8 px-6 lg:px-[220px] 2xl:px-[400px] flex flex-col gap-y-3.5">
				<h1 className="text-primary text-sm max-lg:text-center lg:text-xl font-bold py-[15px] lg:pb-8 lg:border-b border-[#D3E3F1]">
					{t(tKey('changePassword.privacySettings'))}
				</h1>
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-5 py-4">
					<h3 className="lg:text-lg text-sm text-primary font-bold">
						{t(tKey('changePassword.title'))}
					</h3>
					<div className="flex flex-col gap-y-5 lg:gap-y-6">
						<LabelInput
							register={register}
							errors={errors}
							type="password"
							name="oldPassword"
							labelText={t(tKey('changePassword.labels.oldPassword'))}
						/>
						<div className="grid grid-cols-1 items-start lg:grid-cols-2 gap-y-5 gap-x-5">
							<LabelInput
								register={register}
								errors={errors}
								type="password"
								name="newPassword"
								labelText={t(tKey('changePassword.labels.newPassword'))}
							/>
							<LabelInput
								register={register}
								errors={errors}
								type="password"
								name="confirmPassword"
								labelText={t(tKey('changePassword.labels.reEnterPassword'))}
							/>
						</div>
					</div>
					<Button className="lg:w-fit lg:ml-auto">{t(tKey('changePassword.labels.save'))}</Button>
				</form>
				<div className="flex justify-between items-center">
					<h1 className="text-primary text-sm max-lg:text-center lg:text-xl font-bold py-[15px] lg:pb-8">
						{t(tKey('changePassword.deactivateAccount'))}
					</h1>
					<ToggleSwitch
						enabled={showDeactivateModal}
						onChange={() => setShowDeactivateModal(true)}
					/>
				</div>
			</div>
		</AppLayout>
	)
}
