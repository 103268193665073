import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Highlights } from 'components/auth/highlights'
import { LabelInput } from 'components/inputs/input'
import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'

interface ForgotPasswordProps {
	register: UseFormRegister<Auth>
	errors: FieldErrors<Auth>
	email: string
	isEmailSent: boolean
	setIsEmailSent: (value: boolean) => void
	onResendClick: () => void
	isLoading: boolean
}

export const ForgotPassword = ({
	register,
	errors,
	email,
	isEmailSent,
	setIsEmailSent,
	onResendClick,
	isLoading
}: ForgotPasswordProps) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useTranslation()
	const tKey = getTKey('auth')

	if (isEmailSent) {
		return (
			<>
				<div className="flex flex-col gap-y-5">
					<h2 className="font-domine text-[#61BC5F] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
						{t(tKey('forgotPassword.labels.thanks'))}
					</h2>
					<p className="text-primary font-bold max-lg:px-5 text-lg lg:text-2xl leading-6 lg:pb-4 max-lg:text-center">
						{t(tKey('forgotPassword.labels.if'))}{' '}
						<span className="font-normal italic">{email}</span>{' '}
						{t(tKey('forgotPassword.labels.message'))}
					</p>
					<p className="text-secondary max-lg:text-center max-lg:px-5">
						{t(tKey('forgotPassword.labels.emailReceived'))}
					</p>
					<div className="text-lg max-lg:text-center">
						<span
							onClick={onResendClick}
							className="text-primary font-semibold underline cursor-pointer">
							{t(tKey('forgotPassword.labels.resend'))}
						</span>{' '}
						/{' '}
						<span
							onClick={() => setIsEmailSent(false)}
							className="text-primary font-semibold underline cursor-pointer">
							{t(tKey('forgotPassword.labels.tryDifferentEmail'))}
						</span>
					</div>
				</div>
				<div className="pt-8">
					<Highlights />
				</div>
			</>
		)
	}

	return (
		<div>
			<h2 className="font-domine text-[#123258] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
				{t(tKey('forgotPassword.title.forgotPassword'))}
			</h2>
			<p className="text-secondary max-lg:px-5 font-nunito text-base font-normal leading-6 pb-4 2xl:pb-8 max-lg:text-center">
				{t(tKey('forgotPassword.title.forgetEmailText'))}
			</p>
			<div className="max-lg:px-5">
				<LabelInput
					autoComplete="true"
					register={register}
					name="email"
					labelText={t(tKey('forgotPassword.labels.email'))}
					errors={errors}
				/>

				<div className="flex justify-center my-6">
					<Button
						className="bg-primary-green whitespace-nowrap text-white w-3/5 py-3 px-0 capitalize text-sm"
						type="submit"
						disabled={isLoading}>
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('forgotPassword.labels.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{t(tKey('forgotPassword.labels.send'))}</span>
						)}
					</Button>
				</div>
			</div>
			<div className="max-lg:pt-8">
				<Highlights />
			</div>
		</div>
	)
}
