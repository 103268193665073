import { StethoscopeIcon } from 'assets/icons';
import { useState } from 'react';

interface ImageSliderProps {
	images: { name: string; url: string }[]
	page?: string
}

export const ImageSlider = ({ images, page }: ImageSliderProps) => {
	const [currentIndex, setCurrentIndex] = useState(0)

	const handleSlider = (index: number) => {
		setCurrentIndex(index)
	}

	return (
		<div className="relative flex items-center justify-center">
			<img
				src={images.length > 0 ? images[0].url : StethoscopeIcon}
				alt="slider"
				className="w-full h-[100px] sm:h-[303px] object-cover object-center"
			/>
		</div>
	)
}
