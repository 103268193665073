import { PlusIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { AppLayout } from 'components/app/layout'
import { MobileNavigation } from 'components/app/mobile-navigation'
import { RelatedPersonCard } from 'components/cards/related-person'
import { useTranslation } from 'react-i18next'
import relationService from 'services/relation-service'
import { getTKey } from 'utils/language'

export const RelatedPersons = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const tKey = getTKey('relatedPersons')

	const [relations, setRelations] = useState<Relation[]>()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		relationService
			.getAllRelations()
			.then(res => setRelations(res))
			.finally(() => setIsLoading(false))
	}, [])

	return (
		<AppLayout renderDashboardHeader>
			{isLoading && (
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t(tKey('labels.pleaseWait'))}
						</span>
					</div>
				</div>
			)}
			<div className="flex flex-col gap-y-3.5 lg:gap-y-10 px-5 lg:px-[60px] p-3 py-8">
				<div className="flex justify-between items-center">
					<h1 className="text-primary font-domine lg:text-2xl font-bold">
						{t(tKey('labels.relatedPersons'))}
					</h1>
					<Button
						onClick={() => navigate('/related-persons/create')}
						className="max-lg:pl-1 max-lg:pr-2 max-lg:py-[7px] search-shadow px-5 py-3.5">
						<div className="flex gap-x-1 lg:gap-x-2">
							<PlusIcon className="h-4 w-4" />
							<span className="lg:font-semibold text-sm"> {t(tKey('labels.addNew'))}</span>
						</div>
					</Button>
				</div>
				<div className="flex flex-col lg:flex-row gap-y-3.5 lg:gap-x-5">
					{relations?.map(data => (
						<RelatedPersonCard
							key={data._id}
							id={data._id}
							fname={data.fname}
							lname={data.lname}
							address={`${data.address} ${data.streetNumber}, ${data.zip} ${data.city}`}
							insuranceNumber={data.insuranceNumber}
							relation={data.relation}
							birthDate={data.birthDate}
						/>
					))}
				</div>
			</div>
			<MobileNavigation />
		</AppLayout>
	)
}
