export const blankIdentityForm = (): Partial<AppointmentForm> => ({
	fname: '',
	lname: '',
	birthDate: '' as any,
	insuranceNumber: '',
	address: '',
	streetNumber: '',
	country: '',
	city: '',
	relatedPerson: 'other',
	relation: '',
	zip: ''
})
