import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import Routing from 'routing'
import store from 'store'

import 'react-phone-input-2/lib/style.css'
import 'react-phone-number-input/style.css'
import 'react-step-progress-bar/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/main.css'
import './i18n'
import '/node_modules/flag-icons/css/flag-icons.min.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const persistor = persistStore(store)

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ToastContainer limit={3} newestOnTop={true} pauseOnFocusLoss={false} />
				<Routing />
			</PersistGate>
		</Provider>
	</React.StrictMode>
)
