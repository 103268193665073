import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { InputCalendarIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { AppLayout } from 'components/app/layout'
import { InputNumber, LabelInput } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { Language } from 'constants/constants'
import relationService from 'services/relation-service'
import { getAppLang, getTKey } from 'utils/language'

export const CreateOrEditPerson = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { id } = useParams()
	const isNew = !id
	const tKey = getTKey('relatedPersons')

	const schema = yup.object({
		fname: yup.string().required(t('auth.errors.firstname')),
		lname: yup.string().required(t('auth.errors.lastname')),
		gender: yup.string().required(t('auth.errors.gender')),
		insuranceNumber: yup
			.string()
			.test('insurance-number-test', t('auth.errors.validInsuranceNumber'), function (value) {
				if (value && !/^80756\d{15}$/.test(value)) {
					return false
				}
				return true
			}),
		birthDate: yup.date().typeError(t('auth.errors.dob')),
		streetNumber: yup.string().required(t('auth.errors.streetNumber')),
		relation: yup.string().required(t('auth.errors.relation')),
		country: yup.string().required(t('auth.errors.country')),
		address: yup.string().required(t('auth.errors.address')),
		city: yup.string().required(t('auth.errors.city')),
		zip: yup.string().required(t('auth.errors.zip'))
	})

	const [isLoading, setIsLoading] = useState(false)

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control
	} = useForm<Relation>({
		resolver: yupResolver(schema as any),
		defaultValues: { country: getAppLang() === Language.EN ? 'Switzerland' : 'Schweiz' },
		mode: 'all'
	})

	useEffect(() => {
		if (id) {
			relationService.getRelationById(id).then(res => {
				reset({
					...res,
					birthDate: DateTime.fromMillis(res.birthDate).toISODate() as any
				})
			})
		}
	}, [id])

	const onSubmit = (data: Relation) => {
		setIsLoading(true)
		const payload = {
			...data,
			birthDate: DateTime.fromJSDate(data.birthDate as any).toMillis(),
			created: DateTime.now().toMillis()
		}
		if (isNew) {
			relationService
				.createRelation(payload)
				.then(() => {
					toast.success(t(tKey('toast.relationCreated')))
					navigate('/related-persons')
				})
				.catch(() => toast.error(t(tKey('toast.errorRelationCreated'))))
				.finally(() => setIsLoading(false))
		} else {
			relationService
				.updateRelation(id, payload)
				.then(() => {
					toast.success(t(tKey('toast.relationUpdated')))
					navigate('/related-persons')
				})
				.catch(() => toast.error(t(tKey('toast.errorRelationUpdated'))))
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<AppLayout renderDashboardHeader>
			<form onSubmit={handleSubmit(onSubmit)} className="py-8 px-10 xl:px-32 2xl:px-52">
				<div className="lg:pb-8 pb-4 lg:border-b lg:border-[#D3E3F1]">
					<h1 className="font-domine max-lg:text-center text-sm text-primary lg:text-[22px] font-bold">
						{isNew
							? t(tKey('form.headings.addRelatedPerson'))
							: t(tKey('form.headings.editRelatedPerson'))}
					</h1>
				</div>
				<div className="flex flex-col gap-y-5 mt-3.5">
					<h2 className="text-primary font-domine font-bold text-sm lg:text-lg">
						{t(tKey('form.titles.basicInformation'))}
					</h2>

					<Controller
						control={control}
						name="gender"
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-col">
								<div className="flex flex-row gap-x-3">
									<RadioInput
										labelText={t(tKey('form.labels.male'))}
										onChange={onChange}
										name="gender"
										register={register}
										value="male"
										checked={value === 'male'}
									/>
									<RadioInput
										labelText={t(tKey('form.labels.female'))}
										onChange={onChange}
										register={register}
										name="gender"
										value="female"
										checked={value === 'female'}
									/>
								</div>
								{errors?.gender && (
									<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
								)}
							</div>
						)}
					/>
					<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
						<LabelInput
							register={register}
							errors={errors}
							name="fname"
							labelText={t(tKey('form.labels.firstName'))}
						/>
						<LabelInput
							register={register}
							errors={errors}
							name="lname"
							labelText={t(tKey('form.labels.lastName'))}
						/>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:grid-flow-col gap-x-5">
						<LabelInput
							register={register}
							errors={errors}
							name="relation"
							labelText={t(tKey('form.labels.relation'))}
						/>
						<div className="relative z-20 grow flex items-center">
							<LabelInput
								name="birthDate"
								register={register}
								errors={errors}
								labelText={t(tKey('form.labels.dob'))}
								type="date"
							/>
							<div
								className={clsx('absolute -z-20 right-3', {
									'-translate-y-2.5': errors?.birthDate
								})}>
								<img src={InputCalendarIcon} />
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:flex-row gap-x-5">
						<Controller
							control={control}
							name="insuranceNumber"
							render={({ field: { onChange, value } }) => (
								<InputNumber
									onChange={onChange}
									value={value}
									errors={errors}
									register={register}
									maxLength={20}
									name="insuranceNumber"
									labelText={t(tKey('form.labels.insuranceNo'))}
									type="number"
								/>
							)}
						/>
						<div className="max-lg:hidden" />
					</div>
				</div>
				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-sm lg:text-lg">
						{t(tKey('form.headings.patientAddress'))}
					</h2>
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-5 lg:flex-row gap-x-5">
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('form.labels.location'))}
								name="address"
							/>
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('form.labels.streetNo'))}
								name="streetNumber"
							/>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
							<Controller
								control={control}
								name="zip"
								render={({ field: { onChange, value } }) => (
									<InputNumber
										onChange={onChange}
										value={value}
										register={register}
										errors={errors}
										labelText={t(tKey('form.labels.zipCode'))}
										name="zip"
									/>
								)}
							/>
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('form.labels.city'))}
								name="city"
							/>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
							<LabelInput
								register={register}
								errors={errors}
								labelText={t(tKey('form.labels.country'))}
								name="country"
							/>

							<div className="max-lg:hidden" />
						</div>
					</div>
					<Button
						disabled={isLoading}
						className="lg:w-fit lg:ml-auto lg:font-bold max-lg:font-bold">
						{isNew ? (
							isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t('auth.login.labels.pleaseWait')}
									</span>
								</div>
							) : (
								<span>{t(tKey('form.buttons.add'))}</span>
							)
						) : isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t('auth.login.labels.pleaseWait')}
								</span>
							</div>
						) : (
							<span>{t(tKey('form.buttons.save'))}</span>
						)}
					</Button>
				</div>
			</form>
		</AppLayout>
	)
}
