import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import clsx from 'clsx'

interface AccordionProps {
	heading: string
	content: string
}

export const Accordion = ({ heading, content }: AccordionProps) => {
	return (
		<div className="w-full lg:rounded-xl">
			<div
				style={{ boxShadow: '0px 4px 24px 0px #00000014' }}
				className="pt-5 px-5 w-full bg-white">
				<Disclosure>
					{({ open }) => (
						<>
							<Disclosure.Button
								className={clsx(
									'flex border-b border-[#D3E3F1] pb-5 font-bold w-full justify-between text-left max-lg:text-sm capitalize focus:outline-none',
									open ? 'text-[#61BC5F]' : 'text-primary'
								)}>
								<span>{heading}</span>
								<ChevronDownIcon
									className={clsx('h-5 w-5', {
										'text-[#61BC5F] rotate-180 transform': open,
										'text-primary': !open
									})}
								/>
							</Disclosure.Button>
							<Disclosure.Panel className={clsx('text-secondary', { 'py-5': open })}>
								<p className="faq" dangerouslySetInnerHTML={{ __html: content }} />
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>
			</div>
		</div>
	)
}
