import { Language } from 'constants/constants'
import { getAppLang } from 'utils/language'

export const calculateServicePrice = (price: string, healthInsurance: boolean) => {
	const appLanguage = getAppLang()
	if (!healthInsurance && price === '0')
		return appLanguage === Language.EN ? 'Available for free' : 'Kostenlos erhältlich'
	if (price && healthInsurance)
		return appLanguage === Language.EN
			? 'Covered by Health Insurance'
			: 'Von der Krankenkasse abgedeckt'
	return `CHF ${Number(price).toFixed(2)}`
}
