import { Switch } from '@headlessui/react'
import clsx from 'clsx'

interface ToggleProps {
	enabled: boolean
	disabled?: boolean
	onChange: (value: boolean) => void
}

export const ToggleSwitch = ({ enabled, disabled, onChange }: ToggleProps) => {
	return (
		<Switch
			checked={enabled}
			disabled={disabled}
			onChange={onChange}
			className={clsx(
				'group relative inline-flex h-5 w-10 flex-shrink-0 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-[#61BC5F] focus:ring-offset-2',
				disabled ? 'cursor-default' : 'cursor-pointer'
			)}>
			<span className="sr-only">Use setting</span>
			<span
				aria-hidden="true"
				className="pointer-events-none absolute h-full w-full rounded-md bg-transparent"
			/>
			<span
				aria-hidden="true"
				className={clsx(
					enabled ? 'bg-[#61BC5F]' : 'bg-gray-200',
					'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
				)}
			/>
			<span
				aria-hidden="true"
				className={clsx(
					enabled ? 'translate-x-5' : 'translate-x-0',
					'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
				)}
			/>
		</Switch>
	)
}
