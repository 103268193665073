
const firebaseCredentials = {
  apiKey: "AIzaSyBaRNQQbHbcBQmMpch_cNs2KpWkCx0pIvM",
  authDomain: "appointo-fb9ff.firebaseapp.com",
  projectId: "appointo-fb9ff",
  storageBucket: "appointo-fb9ff.appspot.com",
  messagingSenderId: "1040177324920",
  appId: "1:1040177324920:web:a4b40a8bc480423c731e8d"
}

export default firebaseCredentials