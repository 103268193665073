import axios from 'services/axios'

const getAllCompanyEmployees = (): Promise<Employee[]> => {
	return axios.get('auth/all-company-employees').then(response => response.data)
}

const getUserInfo = (): Promise<User> => {
	return axios.get(`/auth/self`).then(response => response.data)
}

const getUserDocuments = (): Promise<UserDocument> => {
	return axios.get(`/client/files`).then(response => response.data)
}

const uploadDocument = (data: FormData) => {
	return axios
		.post('/files/client/general', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateUserInfo = (data: FormData) => {
	return axios
		.put('/auth/client', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateUserPassword = (old_password: string, new_password: string) => {
	return axios
		.put('/auth/client/password', { old_password, new_password })
		.then(response => response.data)
}

const deactivateAccount = (password: string) => {
	return axios.put('/auth/client/deactivate', { password }).then(response => response.data)
}

const userService = {
	getAllCompanyEmployees,
	getUserInfo,
	getUserDocuments,
	deactivateAccount,
	uploadDocument,
	updateUserPassword,
	updateUserInfo
}

export default userService
